import React, { useEffect, useState } from "react";
import FilterTabs from "../../components/Partial/FilterTabs";
import { IMAGES } from "../../assets/images";
import { Icons } from "../../assets/icons";
import DonutChart from "../../components/charts/DonutChart";
import { BsThreeDotsVertical } from "react-icons/bs";
import LineGraph from "../../components/charts/LineGraph";
import { DashboardApi } from "../../services/DashboardAPI";
import { FILTER_TABS } from "../../utils/commonUtils";

type Props = {};

const Dashboard = (props: Props) => {
  const [data, setData] = useState<any>({});
  const [filters, setFilters] = useState<any>({
    date: "all",
  });

  const getData = async () => {
    try {
      const params = {
        date: filters.date,
      };
      const res = await DashboardApi.GetDashboardData(params);
      console.log(res);
      if (res.success) {
        setData(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [filters]);

  return (
    <div className="">
      <img
        src={IMAGES.DASHBOARD_BG}
        className="absolute h-[280px] w-full top-0 left-0 "
        style={{ zIndex: "5" }}
      />
      {/* Header */}
      <div className="relative sm:flex justify-between pt-5 z-50 text-main_bg">
        <div>
          <h5 className="text-2xl z-auto">Welcome Back 👋🏻</h5>
          <p className="text-lg">
            Now effortlessly Track your Earnings, Bookings and More - All in One
            Place.
          </p>
        </div>
        {/* <img src={Icons.THREE_DOTS} className="w-[20px] h-[40px]" alt="" /> */}
        <FilterTabs
          filters={FILTER_TABS.REQUESTS}
          value={filters.date}
          onChange={(e) => {
            setFilters({ ...filters, date: e });
          }}
        />
      </div>

      {/* Stats */}
      <div className="grid sm:grid-cols-4 grid-cols-1 gap-6 relative z-10 mt-12">
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img
              src={Icons.DASHBOARD_TOTAL_EARNINGS}
              className="w-[18px] h-[18px]"
              alt=""
            />
            Total Earnings
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">
            ₹
            {data?.completedBookings?.reduce(
              (total: any, curr: any) => total + curr.amount_charge_by_cafe,
              0
            ) || 0}
          </h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+₹150 today</p>
          </div> */}
        </div>
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img
              src={Icons.DASHBOARD_TOTAL_BOOKINGS}
              className="w-[18px] h-[18px]"
              alt=""
            />
            Total Bookings
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">
            {data?.completedBookings?.length || 0}
          </h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+₹15 today</p>
          </div> */}
        </div>
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img
              src={Icons.DASHBOARD_AVG_BILL_VAL}
              className="w-[18px] h-[18px]"
              alt=""
            />
            Avg. Bill Value
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">
            ₹{" "}
            {(
              data?.completedBookings?.reduce(
                (total: any, curr: any) => total + curr.amount_charge_by_cafe,
                0
              ) / data?.completedBookings?.length
            ).toFixed(0) == "NaN"
              ? 0
              : (
                  data?.completedBookings?.reduce(
                    (total: any, curr: any) =>
                      total + curr.amount_charge_by_cafe,
                    0
                  ) / data?.completedBookings?.length
                ).toFixed(0) || 0}
          </h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+₹150 today</p>
          </div> */}
        </div>
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img
              src={Icons.DASHBOARD_OFFER_REDEMPTION}
              className="w-[18px] h-[18px]"
              alt=""
            />
            Offer Redemption
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">₹0</h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+₹150 today</p>
          </div> */}
        </div>
      </div>

      {/* Graphs */}
      <div className="grid grid-cols-3 mt-9 gap-6">
        {/* Donut chart */}
        <div className="sm:col-span-1 col-span-3 rounded-[8px] bg-[white] p-6 border">
          <div className="flex justify-between">
            <div>
              <h4 className="text-xl">Bookings</h4>
              <p className="text-sm">Out of all requests</p>
            </div>
            <BsThreeDotsVertical cursor={"pointer"} />
          </div>
          <DonutChart data={data?.bookingCount || []} />
        </div>
        <div className="sm:col-span-2 col-span-3 rounded-[8px] bg-[white] p-6 border">
          <div className="flex justify-between">
            <div>
              <h4 className="text-xl">Earnings</h4>
              <p className="text-sm">Income of each month</p>
            </div>

            {/* Legend */}
            <div className="flex gap-4 items-center">
              {/* <div className="flex gap-1.5 items-center">
                <div className="h-3 w-3 rounded-full bg-[#23b0c3]"></div>
                <p className="text-xs">Total bill value</p>
              </div> */}
              {/* <div className="flex gap-1.5 items-center">
                <div className="h-3 w-3 rounded-full bg-[#9333EA]"></div>
                <p className="text-xs">Discounts given</p>
              </div> */}
            </div>

            <BsThreeDotsVertical cursor={"pointer"} />
          </div>
          <div className="mt-6">
            <LineGraph data={data?.EarningData || []} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
