import React, { useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import FilterTabs from "../../components/Partial/FilterTabs";
import Table from "../../components/Table/Table";
import { IMAGES } from "../../assets/images";
import Button from "../../components/Partial/Button";
import { Dropdown } from "antd";
import Modal from "../../components/Partial/Modal";
import CompleteBookingPop from "../../components/smallPopups/CompleteBookingPop";
import { BookingApi } from "../../services/BookingAPI";
import { BOOOKING_STATUSES } from "../../utils/attributeValuse";
import { Toast } from "../../wrapper/ToastWrapper";
import moment from "moment";
import BreadCrumb from "../../components/Partial/BreadCrumb";
import {
  BREADCRUMB_OPTION,
  FILTER_TABS,
  SNACKBAR_MESSAGES,
} from "../../utils/commonUtils";

type Props = {};


const Upcoming = (props: Props) => {
  const [isCompleteModal, setIsCompleteModal] = useState(false);
  const handleOpenCompleteModal = () => setIsCompleteModal(true);
  const handleCloseCompleteModal = () => setIsCompleteModal(false);

  const [selectedBooking, setSelectedBooking] = useState<string>();

  const [data, setData] = useState<any[]>([]);
  const [amount, setAmount] = useState<string>("");

  const [filters, setFilters] = useState<any>({
    date: "all",
  });

  const getData = async () => {
    // setLoading(true);

    const params = {
      date: filters.date,
    };

    try {
      const res = await BookingApi.GetBookings(
        BOOOKING_STATUSES.ACCEPTED,
        params
      );
      if (res && res.success) {
        setData(res.data);
        console.log("res.data", res.data);
      }
    } catch (err) {
      Toast.Error("Error while fetching data");
    }
    // setLoading(false);
  };

  const handleSubmit = async (status: string, bookingId?: any) => {
    console.log("selectedBooking", selectedBooking, bookingId);
    if (status === BOOOKING_STATUSES.COMPLETED && !amount) {
      Toast.Error("Please enter amount");
      return;
    }

    // setLoading(true);
    try {
      const res = await BookingApi.UpdateBooking(bookingId, {
        status: status,
        amount_charge_by_cafe: amount || null,
      });
      if (res && res.success) {
        if (status === BOOOKING_STATUSES.COMPLETED) {
          Toast.Success(SNACKBAR_MESSAGES.MARK_AS_COMPLETED);
        }
        if (status === BOOOKING_STATUSES.DID_NOT_SHOW) {
          Toast.Error(SNACKBAR_MESSAGES.MARK_DID_NOT_SHOW_UP);
        }
        setSelectedBooking(undefined);
        handleCloseCompleteModal();
        getData();
      }
    } catch (err) {
      Toast.Error("Error while approving booking");
    }
  };

  const columns:any = [
    {
      title: "Booking ID",
      dataIndex: "_id",
      key: "booking_id",
    },
    {
      title: "User Profile",
      dataIndex: "user",
      render: (d: any, data: any) => (
        <div className="flex gap-3 items-center">
          <div className="flex relative">
            <img
              src={data?.sender?.photos?.[0]?.photoUrl || IMAGES.BannerAuth}
              className="rounded-full h-11 w-11 border"
              alt=""
            />
            <img
              src={data?.receiver?.photos?.[0]?.photoUrl || IMAGES.BannerAuth}
              className="rounded-full h-11 w-11 border -ml-2"
              alt=""
            />
          </div>
          <div>
            <h4 className="text-sm">
              {data?.sender?.name} / {moment().diff(data?.sender?.dob, "years")}
            </h4>
            <h4 className="text-sm">
              {data?.receiver?.name} /{" "}
              {moment().diff(data?.receiver?.dob, "years")}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: "Email ID",
      dataIndex: "sender",
      key: "email",
      render: (d: any, data: any) => (
        <div>
          <h4 className="text-sm">{data?.sender?.email || "Not Mentioned"}</h4>
          <h4 className="text-sm">
            {data?.receiver?.email || "Not Mentioned"}
          </h4>
        </div>
      ),
    },
    {
      title: "Contact",
      dataIndex: "sender",
      key: "contact",
      render: (d: any) => (
        <div>
          <h4 className="text-sm">{d?.phoneno}</h4>
        </div>
      ),
    },
    {
      title: "Booking date & time",
      dataIndex: "date",
      key: "date",
      render: (_: any, d: any) => (
        <>
          {moment(d.date).format("Do MMM YYYY")} | {moment(d.date).format("LT")}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "_id",
      render: (d: any, all_data: any) => (
        <div>
          {
            moment(all_data.date).isBefore(moment())
              ?
              <Dropdown
                rootClassName="w-[200px] status-dropdown"
                menu={{
                  items: [
                    {
                      label: (
                        <Button
                          state="success"
                          className="border-0 w-full"
                          onClick={() => {
                            console.log("selectedBooking", selectedBooking);
                            handleOpenCompleteModal();
                          }}
                        >
                          <div className="flex gap-2 items-center">Completed</div>
                        </Button>
                      ),
                      key: "1",
                    },
                    {
                      label: (
                        <Button
                          state="danger"
                          className="border-0 w-full"
                          onClick={() => {
                            console.log("selectedBooking", d);

                            handleSubmit(BOOOKING_STATUSES.DID_NOT_SHOW, d);
                          }}
                        >
                          <div className="flex gap-2 items-center">
                            Didn't show up
                          </div>
                        </Button>
                      ),
                      key: "2",
                    },
                  ],
                }}
                placement="bottom"
                trigger={["click"]}
              >
                <Button
                  className="border-0 h-9"
                  onClick={() => {
                    setSelectedBooking(d);
                  }}
                >
                  <div className="flex gap-2 items-center w-[120px] justify-center">
                    {/* Completed */}
                    <AiFillCaretDown />
                  </div>
                </Button>
              </Dropdown>
              :
              <Button
                className="border-0 h-9 cursor-not-allowed opacity-75"
              >
                <div className="flex gap-2 items-center w-[120px] justify-center">
                  {/* Completed */}
                  <AiFillCaretDown />
                </div>
              </Button>

          }
        </div>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [filters.date]);

  useEffect(() => {
    console.log("selectedBooking", selectedBooking);
  }, [selectedBooking]);

  return (
    <div>
      <Modal
        open={isCompleteModal}
        onCancel={handleCloseCompleteModal}
        onOk={handleCloseCompleteModal}
        width={"400px"}
      >
        <CompleteBookingPop
          onCancel={handleCloseCompleteModal}
          handleSubmit={() =>
            handleSubmit(BOOOKING_STATUSES.COMPLETED, selectedBooking)
          }
          value={amount}
          onChange={(e: any) => setAmount(e.target.value)}
        />
      </Modal>
      <h4 className="text-2xl">REQUESTS</h4>

      {/* Breadcrumb */}
      <BreadCrumb items={BREADCRUMB_OPTION.REQUESTS_UPCOMING} />

      <div className="mt-9 flex gap-6 items-center">
        <FilterTabs
          filters={FILTER_TABS.REQUESTS}
          value={filters.date}
          onChange={(e) => {
            setFilters({ ...filters, date: e });
          }}
        />
      </div>

      <div className="mt-6">
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default Upcoming;
