import React, { useContext, useEffect, useRef } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import Button from "../../components/Partial/Button";
import { Icons } from "../../assets/icons";
import Input from "../../components/FormFileds/Input";
import InputNumber from "../../components/FormFileds/InputNumber";
import Slider from "../../components/FormFileds/Slider";
import Select from "../../components/FormFileds/Select";
import { AuthApi } from "../../services/AuthAPI";
import { PORTAL_PAGES } from "../../utils/options";
import { Toast } from "../../wrapper/ToastWrapper";
import { CommonApi } from "../../services/CommonAPI";
import { userContext } from "../../utils/contexts";
import { UserContextInterface } from "../../interfaces";
import axios from "axios";

type Props = {};

const Settings = (props: Props) => {
  const {setUser} = useContext<UserContextInterface>(userContext);
  const [profileData, setProfileData] = React.useState<any>({});
  const [edit, setEdit] = React.useState<boolean>(false);

  const uploadRef = useRef<any>(null);

  const handleChange = (e: any) => {
    if (edit)
      setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const handleChangeSelect = (name: string, value: string) => {
    if (edit) setProfileData({ ...profileData, [name]: value });
  };

  const getData = async () => {
    try {
      const res = await AuthApi.GetMyProfile();
      if (res) {
        setProfileData(res.data);
        setEdit(false);
        const obj = {
          id: res.data._id,
          name: res.data.name,
          email: res.data.email,
          cafe_id: res.data.cafe_id,
          phone: res.data.phone,
          tab: res.data.tab,
          profile_pic: res.data.profile_pic || "",
          role: "cafe_admin",
        }
        sessionStorage.setItem("user", JSON.stringify(obj));
        setUser(obj);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  const handleUpdate = async () => {
    var pattern = new RegExp("^[0-9]{10}$");
    if (!pattern.test(profileData.phone)) {
      Toast.Error("Invalid Phone Number");
      return;
    }
    if (
      profileData.alternate_phone &&
      !pattern.test(profileData.alternate_phone)
    ) {
      Toast.Error("Invalid Alternate Phone Number");
      return;
    }
    try {
      const res = await AuthApi.UpdateMyProfile(profileData);
      if (res) {
        Toast.Success("Profile updated successfully");
        setEdit(false);
        getData();
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  const handleImageUpload = async (key: string, e: any) => {
    if (!edit) return Toast.Error("You can't upload images in non-edit mode");

    console.log(e.target.files[0]);
    const formData = new FormData();
    formData.append("photos", e.target.files[0]);
    console.log(formData);

    try {
      const url = await CommonApi.UploadToCloud(formData);
      if (url && url.data) {
        setProfileData((prev: any) => ({
          ...prev,
          [key]: url.data,
        }));
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
    // setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleLogout = async () => {
    sessionStorage.clear();
    window.location.reload();
  };
  return (
    <div>
      <div className="flex justify-between items-end">
        <div>
          <h4 className="text-2xl">SETTINGS</h4>

          {/* Breadcrumb */}
          <div className="flex gap-2 items-center mt-2 fw-400">
            <p className="cursor-pointer text-primary">Dashboard</p>
            <AiFillCaretRight className="text-gray-400" />
            <p className="text-gray-400">Settings</p>
          </div>
        </div>
        {edit ? (
          <div className="flex gap-4 items-center">
            <Button
              type="filled"
              state="black-outlined"
              className="px-4 flex items-center gap-1 text-[black] bg-transparent h-10"
              onClick={() => {
                setEdit(false);
                getData();
              }}
            >
              <img src={Icons.RECENT} className="h-4 w-4" alt="Reset" /> Reset
            </Button>
            <Button
              onClick={handleUpdate}
              type="filled"
              state="success"
              className="px-8 h-10"
            >
              Save
            </Button>
          </div>
        ) : (
          <Button
            onClick={() => {
              setEdit(true);
            }}
            state="success"
            type="filled"
          >
            <div className="flex gap-1 items-center">
              <img className="h-4 w-4" src={Icons.PENCIL} alt="Edit" />
              Edit
            </div>
          </Button>
        )}
      </div>
      <div className="grid grid-cols-4">
        <div className="lg:col-span-3 col-span-4">
          {/* Account Setting */}
          <div className="bg-white p-6 mt-6 rounded-lg border">
            <h4 className="text-lg">Account Setting</h4>
            <div className="flex flex-col items-start gap-2 mt-6">
              <div className="h-[64px] w-[64px] rounded-full bg-black_300">
                {profileData?.profile_pic ? (
                  <img
                    src={profileData?.profile_pic}
                    className="h-full w-full object-cover rounded-full"
                    alt=""
                  />
                ) : (
                  <div className="h-[64px] w-[64px] rounded-full bg-black_300"></div>
                )}
              </div>
              {edit ? (
                <>
                  <button
                    onClick={() => {
                      uploadRef.current.click();
                    }}
                    className="bg-transparent border-none text-primary"
                  >
                    Upload Photo
                  </button>
                  <input
                    onChange={(e: any) => {
                      handleImageUpload("profile_pic", e);
                    }}
                    type="file"
                    ref={uploadRef}
                    hidden={true}
                  />
                </>
              ) : null}
            </div>
            <div className="mt-3.5">
              <label htmlFor="" className="text-black_300">
                Admin name
              </label>
              <Input
                className="mt-1 text-black_300"
                placeholder="Enter your name here"
                value={profileData?.name}
                disabled={!edit}
                onChange={handleChange}
                name="name"
              />
            </div>
            <div className="mt-3.5">
              <label htmlFor="" className="text-black_300">
                Email Id
              </label>
              <Input
                className="mt-1 text-black_300"
                placeholder="Enter your email ID here"
                value={profileData?.email}
                disabled={!edit}
                onChange={handleChange}
                name="email"
              />
            </div>
            <div className="mt-3.5">
              <label htmlFor="" className="text-black_300">
                phone number
              </label>
              <div className="mt-1">
                <InputNumber
                  className={`rounded-[8px] h-[40px] px-3 py-1 w-full text-black_300 ${
                    !edit ? "cursor-not-allowed" : ""
                  }`}
                  type="number"
                  prefix="+ 91 | "
                  placeholder="Enter your Phone Number"
                  value={profileData?.phone}
                  disabled={!edit}
                  onChange={(e: any) => {
                    setProfileData({ ...profileData, phone: e });
                  }}
                  name="phone"
                />
              </div>
            </div>
            <div className="mt-3.5">
              <label htmlFor="" className="text-black_300">
                Alternate phone number
              </label>
              <div className="mt-1">
                <InputNumber
                  className={`rounded-[8px] h-[40px] px-3 py-1 w-full text-black_300 ${
                    !edit ? "cursor-not-allowed" : ""
                  }`}
                  type="number"
                  prefix="+ 91 | "
                  placeholder="Enter your Alternate Phone Number"
                  value={profileData?.alternate_phone}
                  disabled={!edit}
                  onChange={(e: any) => {
                    setProfileData({ ...profileData, alternate_phone: e });
                  }}
                  name="alternate_phone"
                />
              </div>
            </div>
          </div>

          {/* Notification */}
          <div className="bg-white p-6 mt-6 rounded-lg border">
            <h4 className="text-lg">Notification</h4>
            <div className="mt-4 flex flex-col gap-2">
              <div className="border rounded-lg py-2.5 px-3 flex justify-between items-center">
                <p className="text-black_300 text-sm">
                  Notify me about every updates
                </p>
                <Slider
                  disabled={!edit}
                  value={profileData?.notification}
                  onChange={(e: any) => {
                    handleChangeSelect("notification", e);
                  }}
                />
              </div>
              <div className="border rounded-lg py-2.5 px-3 flex justify-between items-center">
                <p className="text-black_300 text-sm">
                  Remind users about their upcoming meetings 30 minutes in
                  advance
                </p>
                <Slider
                  disabled={!edit}
                  value={profileData?.reminder}
                  onChange={(e: any) => {
                    handleChangeSelect("reminder", e);
                  }}
                />
              </div>
            </div>
          </div>

          {/* Other Settings */}
          <div className="bg-white p-6 mt-6 rounded-lg border">
            <h4 className="text-lg">Other Settings</h4>
            <div className="mt-4 flex flex-col gap-2">
              <div className="border rounded-lg py-2.5 px-3 flex justify-between items-center">
                <p className="text-black_300 text-sm">
                  Automatically Accept Booking Requests
                </p>
                <Slider
                  disabled={!edit}
                  value={profileData?.auto_accept_booking}
                  onChange={(e: any) => {
                    handleChangeSelect("auto_accept_booking", e);
                  }}
                />
              </div>
            </div>
          </div>

          {/* Open to Start */}
          <div className="bg-white p-6 mt-6 rounded-lg border">
            <h4 className="text-lg">Open on start</h4>
            <div className="mt-4">
              <label htmlFor="" className="text-black_300">
                Choose what to show when portal starts
              </label>
              <Select
                className="mt-4 text-black_300"
                placeholder="Select tab"
                options={PORTAL_PAGES}
                value={profileData?.tab}
                disabled={!edit}
                onChange={(e: any) => {
                  handleChangeSelect("tab", e);
                }}
              />
            </div>
          </div>

          {/* Sign in & account deletion */}
          <div className="bg-white p-6 mt-6 rounded-lg border">
            <h4 className="text-lg">Sign in & account deletion</h4>
            <div className="mt-4 flex flex-col gap-2">
              <div
                className="border rounded-lg py-2.5 px-3 flex justify-between items-center cursor-pointer"
                onClick={handleLogout}
              >
                <p className="text-black_300 text-sm">
                  Log out from this device
                </p>
                <img
                  src={Icons.ARROW_ANGLE_RIGHT}
                  className="h-5 w-5 "
                  alt=""
                />
              </div>
              <div className="border rounded-lg py-2.5 px-3 flex justify-between items-center">
                <p className="text-error text-sm">Delete my account</p>
                <img
                  src={Icons.TRASH_RED}
                  className="h-5 w-5 cursor-pointer"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
