export const PROFILE_CATEGORY = [
    {
        label:"Pocket friendly",
        value:"POCKET_FRIENDLY"
    },
    {
        label:"Pure veg",
        value:"PURE_VEG"
    },
    {
        label:"Quick bite",
        value:"QUICK_BITE"
    },
    {
        label:"Newly Open",
        value:"NEWLY_OPEN"
    },
    {
        label:"Fine dine",
        value:"FINE_DINE"
    },
    {
        label:"Unlimited buffet",
        value:"UNLIMITED_BUFFET"
    },
    {
        label:"Party vibe",
        value:"PARTY_VIBE"
    },
    {
        label:"Coffee",
        value:"COFFEE"
    },
    {
        label:"Rooftop",
        value:"ROOFTOP"
    },
    {
        label:"Chill vibe",
        value:"CHILL_VIBE"
    },
    {
        label:"Nature vibe",
        value:"NATURE_VIBE"
    },
    {
        label:"Beer & cocktails",
        value:"BEER_COCKTAILS"
    },
    {
        label:"Regional food",
        value:"REGIONAL_FOOD"
    },
    {
        label:"Instaworthy",
        value:"INSTAWORTHY"
    },
    {
        label:"Bakery",
        value:"BAKERY"
    },
    {
        label:"Iconic venue",
        value:"ICONIC_VENUE"
    },
    {
        label:"Popular Hangouts",
        value:"POPULAR_HANGOUTS"
    },
    {
        label:"Luxury ",
        value:"LUXURY"
    },
    {
        label:"Stunning ambience",
        value:"STUNNING_AMBIENCE"
    },
]

export const PROFILE_CATEGORY_CUISINS = [
    {
        label:"Chinese",
        value:"CHINESE"
    },
    {
        label:"North Indian",
        value:"NORTH_INDIAN"
    },
    {
        label:"Italian",
        value:"ITALIAN"
    },
    {
        label:"Asian",
        value:"ASIAN"
    },
    {
        label:"Continental",
        value:"CONTINENTAL"
    },
    {
        label:"Dessert",
        value:"DESSERT"
    },
    {
        label:"Fast Food ",
        value:"FAST_FOOD"
    },
    {
        label:"Mexican",
        value:"MEXICAN"
    },
]

export const PROFILE_CATEGORY_FEATURES = [
    {
        label:"Wi-fi",
        value:"WIFI"
    },
    {
        label:"Parking",
        value:"PARKING"
    },
    {
        label:"Smoking",
        value:"SMOKING"
    },
    {
        label:"Pet allowed",
        value:"PET_ALLOWED"
    }
]