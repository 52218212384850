import React from "react";

type Props = {
  filters: any[];
  value: any;
  onChange: (value: any) => void;
};

const FilterTabs = (props: Props) => {
  return (
    <div>
      <div className="p-1 rounded-[8px] bg-[white] h-auto">
        <div className="flex gap-2 overflow-x-auto">
          {props.filters.map((filter, index) => (
            <div
              key={index}
              onClick={() => {
                props.onChange(filter.value);
              }}
              className={`px-3 py-1.5 text-[14px] cursor-pointer ${
                filter.value === props.value
                  ? "rounded-[6px] text-black_500 bg-border_light"
                  : "text-gray-500 bg-transparent"
              }`}
            >
              {filter.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FilterTabs;
