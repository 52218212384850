import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const ProfileApi = {
  GetMyCafe: async (cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/cafe",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  UpdateMyCafe: async (data, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/cafe",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(ProfileApi);
