import { Puff } from "react-loader-spinner";

type Props = {};

const Loader = (props: Props) => {
  return (
    <>
      <div className="absolute h-full w-full left-0 right-0 top-0 z-50 flex justify-center items-center bg-black bg-opacity-10"></div>
      <div className="absolute left-0 top-0 h-screen w-full z-50 flex justify-center items-center">
        <Puff
          height="80"
          width="80"
          radius={2}
          color="#F5793D80"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </>
  );
};

export default Loader;
