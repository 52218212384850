import React, { useEffect, useState } from "react";
import FilterTabs from "../../components/Partial/FilterTabs";
import Table from "../../components/Table/Table";
import { IMAGES } from "../../assets/images";
import Button from "../../components/Partial/Button";
import { BookingApi } from "../../services/BookingAPI";
import { BOOOKING_STATUSES } from "../../utils/attributeValuse";
import { Toast } from "../../wrapper/ToastWrapper";
import moment from "moment";
import BreadCrumb from "../../components/Partial/BreadCrumb";
import { BREADCRUMB_OPTION, FILTER_TABS } from "../../utils/commonUtils";

type Props = {};

const Completed = (props: Props) => {

  const [data, setData] = useState<any[]>([]);

  const [filters, setFilters] = useState<any>({
    date: "all",
  });

  const getData = async () => {
    // setLoading(true);

    const params = {
      date: filters.date,
    };

    try {
      const res = await BookingApi.GetBookings(
        BOOOKING_STATUSES.DID_NOT_SHOW,
        params
      );
      if (res && res.success) {
        setData(res.data);
      }
    } catch (err) {
      Toast.Error("Error while fetching data");
    }
    // setLoading(false);
  };

  const columns = [
    {
      title: "Booking ID",
      dataIndex: "_id",
      key: "booking_id",
    },
    {
      title: "User Profile",
      dataIndex: "user",
      render: (d: any, data: any) => (
        <div className="flex gap-3 items-center">
          <div className="flex relative">
            <img
              src={data?.sender?.photos?.[0]?.photoUrl || IMAGES.BannerAuth}
              className="rounded-full h-11 w-11 border"
              alt=""
            />
            <img
              src={data?.receiver?.photos?.[0]?.photoUrl || IMAGES.BannerAuth}
              className="rounded-full h-11 w-11 border -ml-2"
              alt=""
            />
          </div>
          <div>
            <h4 className="text-sm">
              {data?.sender?.name} / {moment().diff(data?.sender?.dob, "years")}
            </h4>
            <h4 className="text-sm">
              {data?.receiver?.name} /{" "}
              {moment().diff(data?.receiver?.dob, "years")}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: "Email ID",
      dataIndex: "sender",
      key: "email",
      render: (d: any, data: any) => (
        <div>
          <h4 className="text-sm">{data?.sender?.email || "Not Mentioned"}</h4>
          <h4 className="text-sm">
            {data?.receiver?.email || "Not Mentioned"}
          </h4>
        </div>
      ),
    },
    {
      title: "Contact",
      dataIndex: "sender",
      key: "contact",
      render: (d: any) => (
        <div>
          <h4 className="text-sm">{d?.phoneno}</h4>
        </div>
      ),
    },
    {
      title: "Booking date & time",
      dataIndex: "date",
      key: "date",
      render: (_: any, d: any) => (
        <>
          {moment(d.date).format("Do MMM YYYY")} | {moment(d.date).format("LT")}
        </>
      ),
    },
    {
      title: "Status",
      render: (d: any) => (
        <div>
          <Button state="danger" className="border-0">
            <div className="flex gap-2 items-center">
              Didn't show up
              {/* <AiFillCaretDown /> */}
            </div>
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.date]);

  return (
    <div>
      <h4 className="text-2xl">REQUESTS</h4>

      {/* Breadcrumb */}
      <BreadCrumb items={BREADCRUMB_OPTION.REQUESTS_DIDNT_SHOW} />

      <div className="mt-9 flex gap-6 items-center">
        <FilterTabs
          filters={FILTER_TABS.REQUESTS}
          value={filters.date}
          onChange={(e) => {
            setFilters({ ...filters, date: e });
          }}
        />
      </div>

      <div className="mt-6">
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default Completed;
