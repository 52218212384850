import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const BookingApi = {
  UpdateBooking: async (id, data, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/cafe/bookings/" + id,
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  UpdateManyBookings: async (status, data, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/booking/update-all/" + status,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  GetBookings: async (status, params, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/booking/" + status,
      method: "GET",
      params: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(BookingApi);
