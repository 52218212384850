import React, { useMemo } from "react";
import { Column } from "@ant-design/plots";
import { ColumnConfig } from "@ant-design/charts";
import { months } from "../../utils/options";

type Props = {
  data: any
};

const LineGraph = ({data}: Props) => {
  const mainData = useMemo(()=>{
  return data.map((d: any) => {
    return {
      month: months[d.month],
      type: "Total bill Value",
      value: d.count || 0,
    };
  })},[data]
  );

  const config: ColumnConfig = {
    data:mainData,
    legend: false,
    xField: "month",
    yField: "value",
    seriesField: "type",
    isGroup: true,
    columnStyle: {
      radius: [20, 20, 0, 0],
    },
    color: ["#23b0c3", "#9333EA"],
    // lagend: {
    //   visibility: "none",
    //   style: {
    //     display: "none",
    //   },
    // },
  };

  return <Column {...config} />;
};

export default LineGraph;
