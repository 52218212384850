import React, { useEffect } from "react";
import Button from "../../../components/Partial/Button";
import { Icons } from "../../../assets/icons";
import Input from "../../../components/FormFileds/Input";
import InputNumber from "../../../components/FormFileds/InputNumber";
import BreadCrumb from "../../../components/Partial/BreadCrumb";
import { BREADCRUMB_OPTION } from "../../../utils/commonUtils";

type Props = {};

const Settings = (props: Props) => {
  const [profileData, setProfileData] = React.useState<any>({});
  const [edit, setEdit] = React.useState<boolean>(false);

  const handleChange = (e: any) => {
    if (edit)
      setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const getData = async () => {
    // try {
    //   const res = await AuthApi.GetMyProfile();
    //   if (res) {
    //     setProfileData(res.data);
    //     setEdit(false);
    //   }
    // } catch (err) {
    //   toast.error("Something went wrong");
    // }
  };

  const handleUpdate = async () => {
    // try {
    //   const res = await AuthApi.UpdateMyProfile(profileData);
    //   if (res) {
    //     Toast.Success("Profile updated successfully");
    //     setEdit(false);
    //   }
    // } catch (err) {
    //   toast.error("Something went wrong");
    // }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleLogout = async () => {
    sessionStorage.clear();
    window.location.reload();
  };

  return (
    <div>
      <div className="flex justify-between items-end">
        <div>
          <h4 className="text-2xl">SETTINGS</h4>

          {/* Breadcrumb */}
          <BreadCrumb items={BREADCRUMB_OPTION.ADMIN.SETTINGS} />
        </div>
        {edit ? (
          <div className="flex gap-4 items-center">
            <Button
              type="filled"
              state="black-outlined"
              className="px-4 flex items-center gap-1 text-[black] bg-transparent h-10"
              onClick={() => {
                setEdit(false);
                getData();
              }}
            >
              <img src={Icons.RECENT} className="h-4 w-4" alt="Reset" /> Reset
            </Button>
            <Button
              onClick={handleUpdate}
              type="filled"
              state="success"
              className="px-8 h-10"
            >
              Save
            </Button>
          </div>
        ) : (
          <Button
            onClick={() => {
              setEdit(true);
            }}
            state="success"
            type="filled"
          >
            <div className="flex gap-1 items-center">
              <img className="h-4 w-4" src={Icons.PENCIL} alt="Edit" />
              Edit
            </div>
          </Button>
        )}
      </div>
      <div className="grid grid-cols-4">
        <div className="lg:col-span-3 col-span-4">
          {/* Account Setting */}
          <div className="bg-white p-6 mt-6 rounded-lg border">
            <h4 className="text-lg">Account Setting</h4>
            <div className="flex flex-col items-start gap-2 mt-6">
              <div className="h-[64px] w-[64px] rounded-full bg-black_300"></div>
              {edit ? (
                <button className="bg-transparent border-none text-primary">
                  Upload Photo
                </button>
              ) : null}
            </div>
            <div className="mt-3.5">
              <label htmlFor="" className="text-black_300">
                Admin name
              </label>
              <Input
                className="mt-1 text-black_300"
                placeholder="Enter your name here"
                value={profileData?.name}
                disabled={!edit}
                onChange={handleChange}
                name="name"
              />
            </div>
            <div className="mt-3.5">
              <label htmlFor="" className="text-black_300">
                phone number
              </label>
              <div className="mt-1">
                <InputNumber
                  className={`rounded-[8px] h-[40px] px-3 py-1 w-full text-black_300 ${
                    !edit ? "cursor-not-allowed" : ""
                  }`}
                  type="number"
                  prefix="+ 91 | "
                  placeholder="Enter your Phone Number"
                  value={profileData?.phone}
                  disabled={!edit}
                  onChange={(e: any) => {
                    setProfileData({ ...profileData, phone: e });
                  }}
                  name="phone"
                />
              </div>
            </div>
            <div className="mt-3.5">
              <label htmlFor="" className="text-black_300">
                Email Id
              </label>
              <Input
                className="mt-1 text-black_300"
                placeholder="Enter your email ID here"
                value={profileData?.email}
                disabled={!edit}
                onChange={handleChange}
                name="email"
              />
            </div>
          </div>

          {/* Sign in & account deletion */}
          <div className="bg-white p-6 mt-6 rounded-lg border">
            <div className=" flex flex-col gap-2">
              <div
                className="border rounded-lg py-2.5 px-3 flex justify-between items-center cursor-pointer"
                onClick={handleLogout}
              >
                <p className="text-black_300 text-sm">
                  Log out from this device
                </p>
                <img
                  src={Icons.ARROW_ANGLE_RIGHT}
                  className="h-5 w-5 "
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
