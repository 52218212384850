import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const AuthApi = {
  PostLogin: async (data, cancel = false) => {
    const response = await api.request({
      url: "/cafeAdmin/login",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  PostCheckUser: async (data, cancel = false) => {
    const response = await api.request({
      url: "/cafeAdmin/check-user",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  GetMyProfile: async (cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/cafeAdmin",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  UpdateMyProfile: async (data, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/cafeAdmin",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(AuthApi);
