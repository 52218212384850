import { toast } from "react-toastify";
import { Icons } from "../assets/icons";

const Success = (message: string) => {
  const handleClose = () => {
    // Close the toast
    toast.dismiss();
  };
  return toast(
    <div className="bg-primary_light text-primary p-0 flex items-start gap-4">
      <img src={Icons.TOAST_SUCCESS} alt="" />
      <div className="grow">{message}</div>
      <div className="p-[5px]">
        <img
          src={Icons.CROSS_GREEN}
          onClick={handleClose}
          className="cursor-pointer"
          alt=""
        />
      </div>
    </div>,
    {
      className: "bg-primary_light text-primary font-400 text-sm",
      bodyClassName: "m-0 bg-primary_light text-primary p-4",
      progressClassName: "bg-primary_light text-primary",
      theme: "colored",
    }
  );
};

const Error = (message: any) => {
  const handleClose = () => {
    // Close the toast
    toast.dismiss();
  };
  return toast(
    <div className="bg-error_light text-error p-0 flex items-start gap-4 ">
      <img src={Icons.TOAST_ERROR} alt="" />
      <div className="grow" dangerouslySetInnerHTML={{ __html: message }}></div>
      <div className="p-[5px]">
        <img
          src={Icons.CROSS_RED}
          onClick={handleClose}
          className="cursor-pointer"
          alt=""
        />
      </div>
    </div>,
    {
      className: "bg-error_light text-error font-400 text-sm",
      bodyClassName: "m-0 bg-error_light text-error p-4",
      // toastClassName: "bg-error_light text-error",

      progressClassName: "bg-error_light text-error",
      theme: "colored",
    }
  );
};

export const Toast = {
  Success,
  Error,
};
