import React from "react";
import { IMAGES } from "../../assets/images";

type Props = {
  label: string;
};

const Nodata = (props: Props) => {
  return (
    <div className="flex flex-col justify-center items-center gap-8">
      <img src={IMAGES.NO_DATA} className="w-[280px]" alt="" />
      <p className="text-[32px] text-[#959CB6]">{props.label}</p>
    </div>
  );
};

export default Nodata;
