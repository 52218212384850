import React from "react";
import "./App.css";
import Router from "./router/Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <Router />
      <ToastContainer
        className={"p-0 w-auto min-w-[700px]"}
        bodyClassName={"p-0 m-0 w-auto"}
        toastClassName={"p-0 w-auto"}
        progressClassName={"p-0 w-auto"}
        hideProgressBar={true}
        draggable
        closeButton={false}
        autoClose={3000}
        icon={false}
        position="top-center"
      />
    </div>
  );
}

export default App;
