export const DISCOUNT_TYPES = [
  {
    label: "Flat Off",
    value: "FLAT_OFF",
  },
  {
    label: "Percent Off",
    value: "PERCENT_OFF",
  },
  {
    label: "First Time",
    value: "FIRST_TIME",
  },
  {
    label: "Amount Off",
    value: "AMOUNT_OFF",
  },
];

export const DISCOUNT_VALIDITY = [
  {
    label: "24 Hours",
    value: "HOURS_24",
  },
  {
    label: "7 Days",
    value: "DAYS_7",
  },
  {
    label: "1 Month",
    value: "MONTH_1",
  },
  {
    label: "Unlimited",
    value: "UNLIMITED",
  },
  {
    label: "Custom",
    value: "CUSTOM",
  },
];

export const PORTAL_PAGES = [
  {
    label: "Dashboard",
    value: "DASHBOARD",
  },
  // {
  //   label: "Offers",
  //   value: "OFFERS",
  // },
  {
    label: "Profile",
    value: "PROFILE",
  },
  {
    label: "Settings",
    value: "SETTINGS",
  },
  {
    label: "Requests",
    value: "PENDING",
  },
];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const USER_DATA = months.map((month, index) => {
  return {
    month: month,
    category: "users",
    value: index + Math.random() * 10,
  };
});
export const BOOKING_DATA = months.map((month, index) => {
  return {
    month: month,
    category: "booking",
    value: index + Math.random() * 10,
  };
});
export const CONNECTION_DATA = months.map((month, index) => {
  return {
    month: month,
    category: "connection",
    value: index + Math.random() * 10,
  };
});
