import React, { useEffect } from "react";
import { IMAGES } from "../../assets/images";
import Input from "../../components/FormFileds/Input";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "../../services/AuthAPI";
import { Icons } from "../../assets/icons";
import { Toast } from "../../wrapper/ToastWrapper";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../config/firebase.config";
import { LINKS } from "../../utils/links";
import Loader from "../../components/Partial/Loader";

type Props = {};

declare global {
  interface Window {
    recaptchaVerifier?: any;
    confirmationResult?: any;
  }
}

const Login = (props: Props) => {
  const [mobile, setMobile] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const configureCaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
        callback: (response: any) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
      },
      auth
    );
  };

  const onSignup = async () => {
    setLoading(true);
    try {
      const checkUser = await AuthApi.PostCheckUser({ phone: mobile });
      if (checkUser.success) {
        configureCaptcha();
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, "+91" + mobile, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            navigate("/verify", { state: { mobile } });
            setLoading(false);
          })
          .catch((error) => {
            // Error; SMS not sent
            console.log(error);
            setLoading(false);
          });
      } else {
        setLoading(false);
        Toast.Error("User not found");
      }
    } catch (err: any) {
      setLoading(false);
      console.log(err);
      Toast.Error(err?.response?.data?.message || "User not found");
    }
  };

  useEffect(() => {
    sessionStorage.clear();
    window.recaptchaVerifier = undefined;
  }, []);

  return (
    <div className="">
      {loading ? <Loader /> : null}
      <div className="grid grid-cols-2 gap-8 min-h-screen max-h-screen">
        <div className="col-span-1 lg:block hidden max-h-screen">
          <div
            className="h-[100%] w-[100%]"
            style={{
              backgroundImage: `url(${IMAGES.BannerAuth})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
        <div className="lg:col-span-1 col-span-2 flex justify-between items-center flex-col py-[40px]">
          <div></div>
          <div className="sm:w-[480px] w-[100%] p-5">
            <div className="w-[110px] h-[110px] bg-primary rounded-full p-8">
              <img src={Icons.LOGO_WHITE_NO_BG} className="w-full" alt="Logo" />
            </div>
            <h4 className="text-[36px] mt-8">GET STARTED</h4>
            <p className="text-[20px] mt-4">
              Log in to your account and unlock a world of new connections, and
              earnings
            </p>
            <div id="recaptcha"></div>
            <Input
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter your registered mobile number "
              className="h-[56px] mt-[46px] rounded-[16px]"
            />
            <button
              onClick={() => {
                if (mobile.length === 10) onSignup();
              }}
              className={`w-full py-2 px-4 bg-[#292929] text-[white] rounded-[16px] h-[56px] mt-[35px] ${
                mobile.length !== 10
                  ? "cursor-not-allowed opacity-50"
                  : "cursor-pointer hover:bg-[#1E1E1E] transition-all duration-300 ease-in-out shadow-md"
              }`}
            >
              Continue
            </button>

            <div className="flex items-center justify-between mt-[46px]">
              <hr className="border-[#E0E0E0] h-[1px] w-[45%]" />
              Or
              <hr className="border-[#E0E0E0] h-[1px] w-[45%]" />
            </div>

            <p className="mt-[46px]">
              Want to get your venue listed on congle? <br />
              <a
                href={LINKS.PARTNER_WITH_US}
                target="_blank"
                className="text-normal text-primary" rel="noreferrer"
              >
                Partner with us
              </a>
            </p>
          </div>
          <div className=" w-[100%] self-end">
            <p className="text-center text-[#959CB6] px-5">
              © 2023 ALL RIGHTS RESERVED CONGLE INDIA PVT. LTD.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
