import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const OffersApi = {
  PostAddOffer: async (data, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/offers",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  GetOffers: async (cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/offers",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  GetOffer: async (id, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/offers/" + id,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  DeleteOffer: async (id, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/offers/" + id,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  UpdateOffer: async (id, data, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/offers/" + id,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(OffersApi);
