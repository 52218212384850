import React, { useEffect } from "react";
import Button from "../../../components/Partial/Button";
import { Icons } from "../../../assets/icons";
import Input from "../../../components/FormFileds/Input";
import InputNumber from "../../../components/FormFileds/InputNumber";
import { Toast } from "../../../wrapper/ToastWrapper";
import BreadCrumb from "../../../components/Partial/BreadCrumb";
import {
  BREADCRUMB_OPTION,
  SNACKBAR_MESSAGES,
} from "../../../utils/commonUtils";
import InputArea from "../../../components/FormFileds/InputArea";
import { useNavigate, useParams } from "react-router-dom";
import { AdminApi } from "../../../services/AdminAPI";
import axios from "axios";

type Props = {};

const AddVenue = (props: Props) => {
  const [profileData, setProfileData] = React.useState<any>({
    name: "",
    address: "",
    description: "",
    phone: "",
    location: "",
  });

  const clearData = () => {
    setProfileData({
      name: "",
      address: "",
      description: "",
      phone: "",
      location: "",
    });
  };

  const navigate = useNavigate();

  const { id } = useParams();

  const handleChange = (e: any) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const getVenue = async () => {
    if (!id) return;
    try {
      const res = await AdminApi.GetCafeById(id);
      if (res) {
        setProfileData(res.data);
        console.log(res.data);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await AdminApi.PostAddCafe(profileData);
      if (res) {
        Toast.Success(SNACKBAR_MESSAGES.ADD_VENUE);
        clearData();
        navigate(-1);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  const handleEdit = async () => {
    try {
      const res = await AdminApi.UpdateCafeById(id, profileData);
      if (res) {
        Toast.Success(SNACKBAR_MESSAGES.UPDATE_VENUE);
        getVenue();
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  useEffect(() => {
    if (id) getVenue();
  }, [id]);

  return (
    <div>
      <div className="flex justify-between items-end">
        <div>
          <h4 className="text-2xl">Add Venues</h4>

          {/* Breadcrumb */}
          <BreadCrumb items={BREADCRUMB_OPTION.ADMIN.ADD_VENUES} />
        </div>
        <div className="flex gap-4">
          <Button
            onClick={() => {
              navigate(-1);
            }}
            className="w-[120px]"
            state="black-outlined"
            type="filled"
          >
            <div className="flex items-center gap-1">
              <img src={Icons.CROSS_BLACK} className="w-4 h-4" alt="" />
              Cancel
            </div>
          </Button>
          <Button
            onClick={() => {
              if (id) handleEdit();
              else handleSubmit();
            }}
            className="w-[120px]"
            state="success"
            type="filled"
          >
            Save
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-4 mt-6">
        <div className="lg:col-span-3 col-span-4">
          {/* Basic Information */}
          <div className="bg-[white] p-6 rounded-[8px] border">
            <h4 className="text-lg">Basic Information</h4>
            <div className="mt-3.5">
              <label htmlFor="" className="text-black_300">
                Cafe/Restaurant/venue name
              </label>
              <Input
                className="mt-1 text-black_300"
                placeholder="Eg:. Oia Cafe"
                value={profileData?.name}
                name="name"
                onChange={handleChange}
              />
            </div>
            <div className="mt-3.5">
              <label htmlFor="" className="text-black_300">
                Address
              </label>
              <Input
                className="mt-1 text-black_300"
                placeholder="Eg:. Oia, Hennur Main Road, New Airport Rd, opposite Mantri Webcity, Visthar, Bengaluru, Karnataka 560077"
                value={profileData?.address}
                name="address"
                onChange={handleChange}
              />
            </div>
            <div className="mt-3.5">
              <label htmlFor="" className="text-black_300">
                Location
              </label>
              <Input
                className="mt-1 text-black_300"
                placeholder="Eg:. Enter Google Map Location URL"
                value={profileData?.location}
                name="location"
                onChange={handleChange}
              />
            </div>
            <div className="mt-3.5">
              <label htmlFor="" className="text-black_300">
                Description
              </label>
              <InputArea
                rows={5}
                className="mt-1 text-black_300"
                placeholder="Eg:. Oia Cafe is a beautiful cafe located in the heart of the city. We serve the best coffee and snacks in town."
                value={profileData?.description}
                name="description"
                onChange={handleChange}
              />
            </div>
          </div>
          {/* Contact Information */}
          <div className="bg-[white] p-6 rounded-[8px] border mt-5">
            <h4 className="text-lg">Contact Information</h4>
            <div className="">
              <label htmlFor="" className="text-black_300">
                Contact details
              </label>
              <div className="mt-1">
                <InputNumber
                  className="rounded-[8px]  px-3 py-2 w-full text-black_300"
                  type="number"
                  prefix="+ 91 | "
                  placeholder="Enter phone Number"
                  value={profileData?.phone}
                  onChange={(e: any) => {
                    setProfileData({ ...profileData, phone: e });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVenue;
