import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const DashboardApi = {
  GetDashboardData: async (params, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/cafe-data/dashboard",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(DashboardApi);
