import { ROUTES } from "../router/routerConfig";

export const PAGES_DATA = {
  DASHBOARD: {
    title: "Dashboard",
    link: ROUTES.DASHBOARD,
  },
  PENDING: {
    title: "Pending",
    link: ROUTES.PENDING,
  },
  DIDNT_SHOW: {
    title: "Didn't Show",
    link: ROUTES.DIDNT_SHOW,
  },
  COMPLETED: {
    title: "Completed",
    link: ROUTES.COMPLETED,
  },
  CANCELLED: {
    title: "Cancelled",
    link: ROUTES.CANCELLED,
  },
  UPCOMING: {
    title: "Upcoming",
    link: ROUTES.UPCOMING,
  },
  PROFILE: {
    title: "Profile",
    link: ROUTES.PROFILE,
  },
  OFFERS: {
    title: "Offers",
    link: ROUTES.OFFERS,
  },
  ADD_OFFERS: {
    title: "Add Offers",
    link: ROUTES.ADD_OFFERS,
  },
  OFFER: {
    title: "Offer",
    link: ROUTES.OFFER,
  },
  SETTINGS: {
    title: "Settings",
    link: ROUTES.SETTINGS,
  },

  ADMIN: {
    DASHBOARD: {
      title: "Dashboard",
      link: ROUTES.ADMIN.DASHBOARD,
    },
    ADD_VENUES: {
      title: "Add Venues",
      link: ROUTES.ADMIN.ADD_VENUES,
    },
    USERS: {
      title: "Users",
      link: ROUTES.ADMIN.USERS,
    },
    VENUES: {
      title: "Venues",
      link: ROUTES.ADMIN.VENUES,
    },
    SETTINGS: {
      title: "Settings",
      link: ROUTES.ADMIN.SETTINGS,
    },
  },
};

export const BREADCRUMB_OPTION = {
  DASHBOARD: "Dashboard",
  REQUESTS_PENDING: [PAGES_DATA.DASHBOARD, PAGES_DATA.PENDING],
  REQUESTS_DIDNT_SHOW: [PAGES_DATA.DASHBOARD, PAGES_DATA.DIDNT_SHOW],
  REQUESTS_COMPLETED: [PAGES_DATA.DASHBOARD, PAGES_DATA.COMPLETED],
  REQUESTS_CANCELLED: [PAGES_DATA.DASHBOARD, PAGES_DATA.CANCELLED],
  REQUESTS_UPCOMING: [PAGES_DATA.DASHBOARD, PAGES_DATA.UPCOMING],
  PROFILE: [PAGES_DATA.DASHBOARD, PAGES_DATA.PROFILE],
  OFFERS: [PAGES_DATA.DASHBOARD, PAGES_DATA.OFFERS],
  ADD_OFFERS: [PAGES_DATA.DASHBOARD, PAGES_DATA.OFFERS, PAGES_DATA.ADD_OFFERS],
  OFFER: [PAGES_DATA.DASHBOARD, PAGES_DATA.OFFERS, PAGES_DATA.OFFER],
  SETTINGS: [PAGES_DATA.DASHBOARD, PAGES_DATA.SETTINGS],
  ADMIN: {
    ADD_VENUES: [
      PAGES_DATA.ADMIN.DASHBOARD,
      PAGES_DATA.ADMIN.VENUES,
      PAGES_DATA.ADMIN.ADD_VENUES,
    ],
    USERS: [PAGES_DATA.ADMIN.DASHBOARD, PAGES_DATA.ADMIN.USERS],
    VENUES: [PAGES_DATA.ADMIN.DASHBOARD, PAGES_DATA.ADMIN.VENUES],
    SETTINGS: [PAGES_DATA.ADMIN.DASHBOARD, PAGES_DATA.ADMIN.SETTINGS],
  },
};

export const FILTER_TABS_CATEGORY = {
  ALL_DAY: {
    label: "All Date",
    value: "all",
  },
  LAST_12_MONTHS: {
    label: "12 Months",
    value: "365",
  },
  LAST_30_DAYS: {
    label: "30 Days",
    value: "30",
  },
  LAST_7_DAYS: {
    label: "7 Days",
    value: "7",
  },
  LAST_24_HOURS: {
    label: "24 Hour",
    value: "1",
  },
};

export const FILTER_TABS = {
  DASHBOARD: [
    FILTER_TABS_CATEGORY.ALL_DAY,
    FILTER_TABS_CATEGORY.LAST_12_MONTHS,
    FILTER_TABS_CATEGORY.LAST_30_DAYS,
    FILTER_TABS_CATEGORY.LAST_7_DAYS,
  ],
  REQUESTS: [
    FILTER_TABS_CATEGORY.ALL_DAY,
    FILTER_TABS_CATEGORY.LAST_30_DAYS,
    FILTER_TABS_CATEGORY.LAST_7_DAYS,
    FILTER_TABS_CATEGORY.LAST_24_HOURS,
  ],
  USERS: [
    FILTER_TABS_CATEGORY.ALL_DAY,
    FILTER_TABS_CATEGORY.LAST_7_DAYS,
    FILTER_TABS_CATEGORY.LAST_24_HOURS,
  ],
  VENUES: [
    FILTER_TABS_CATEGORY.ALL_DAY,
    FILTER_TABS_CATEGORY.LAST_7_DAYS,
    FILTER_TABS_CATEGORY.LAST_24_HOURS,
  ],
};

export const SNACKBAR_MESSAGES = {
  MARK_DID_NOT_SHOW_UP: `<span>We're sorry to hear that your scheduled guest didn't show up. We apologise for any inconvenience caused and appreciate your understanding.<br/><br/><br/> Repeated no-show behaviour may lead to the user being blocked from our platform to ensure a respectful and reliable community</span>`,
  MARK_AS_COMPLETED: `Congratulations! You've successfully hosted another meeting. Your venue continues to bring people together`,
  MARK_APPROVE: `Great! You've successfully approved the request(s). We've notified our users about their upcoming meetings`,
  MARK_REJECT: `Your booking request has been successfully rejected. We've informed our users to reschedule the meeting`,
  ADD_OFFER: `Your new offer is now active! You can view its status on the offer page.`,
  INCORRECT_OTP: `Incorrect OTP. Please check and try again`,
  NOT_REGISTERED: `This mobile number is not yet registered with us. For partnership enquiries, please contact our team. We'd be thrilled to have you on board!`,
  ADD_VENUE: `Venue profile has been added successfully`,
  UPDATE_VENUE: `Venue profile has been updated successfully`,
  NOTIFICATION_NEW_BOOKING: `You have a new booking request. Please check your dashboard for more details`,
};
