export const ROUTES = {
  LOGIN: "/",
  VERIFY: "/verify",
  DASHBOARD: "/dashboard",
  PENDING: "/pending",
  DIDNT_SHOW: "/didnt-show",
  COMPLETED: "/completed",
  CANCELLED: "/cancelled",
  UPCOMING: "/upcoming",
  PROFILE: "/profile",
  OFFERS: "/offers",
  ADD_OFFERS: "/add-offers",
  OFFER: "/offer",
  SETTINGS: "/settings",
  ADMIN: {
    LOGIN: "/admin",
    DASHBOARD: "/admin/dashboard",
    USERS: "/admin/users",
    VENUES: "/admin/venues",
    ADD_VENUES: "/admin/add-venue",
    SETTINGS: "/admin/settings",
  },
};

export type RouteKeys = keyof typeof ROUTES;
