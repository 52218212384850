import React, { useEffect, useState } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import Button from "../../components/Partial/Button";
import { Icons } from "../../assets/icons";
import Input from "../../components/FormFileds/Input";
import InputArea from "../../components/FormFileds/InputArea";
import CheckBox from "../../components/FormFileds/CheckBox";
import TImePicker from "../../components/FormFileds/TImePicker";
import InputNumber from "../../components/FormFileds/InputNumber";
import Upload from "../../components/FormFileds/Upload";
import SelectTag from "../../components/FormFileds/SelectTag";
import {
  PROFILE_CATEGORY,
  PROFILE_CATEGORY_CUISINS,
  PROFILE_CATEGORY_FEATURES,
} from "../../utils/ProfileUtils";
import { ProfileApi } from "../../services/ProfileAPI";
import dayjs from "dayjs";
import { Toast } from "../../wrapper/ToastWrapper";
import { CommonApi } from "../../services/CommonAPI";
import axios from "axios";

type Props = {};

const Profile = (props: Props) => {
  // const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>({});
  const [edit, setEdit] = useState<boolean>(false);

  const getData = async () => {
    try {
      const res = await ProfileApi.GetMyCafe();
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  const profilePercentage = () => {
    let count = 0;
    if (data?.name) count++;
    if (data?.address) count++;
    if (data?.description) count++;
    if (data?.open_on?.length) count++;
    if (data?.open_time) count++;
    if (data?.close_time) count++;
    if (data?.price_for_two) count++;
    if (data?.phone) count++;
    if (data?.alternate_phone) count++;
    if (data?.media_ambience_images?.length) count++;
    if (data?.media_food_images?.length) count++;
    if (data?.media_menu_images?.length) count++;
    // if (data?.media_videos?.length) count++;
    if (data?.category?.length) count++;
    if (data?.cuisins?.length) count++;
    if (data?.categoryfeatures?.length) count++;
    return Math.round((count / 15) * 100);
  };

  const handleUpdate = async () => {
    try {
      const res = await ProfileApi.UpdateMyCafe(data);
      if (res) {
        // toast.success("Updated successfully");
        setEdit(false);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageUpload = async (key: string, e: any) => {
    if (!edit) return Toast.Error("You can't upload images in non-edit mode");
    console.log(e);

    var int8view = new Uint8Array(e);
    console.log(int8view);
    // setLoading(true);
    const formData = new FormData();
    formData.append("photos", e[0]);
    try {
      const url = await CommonApi.UploadToCloud(formData);
      if (url && url.data) {
        setData((prev: any) => ({
          ...prev,
          [key]: [...(prev[key] || []), url.data],
        }));
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
    // setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className="max-h-screen-topbar overflow-scroll">
        <div className="flex items-end justify-between sticky top-0 z-[99] bg-main_bg pb-6 px-6">
          {/* Breadcrumb */}
          <div className="">
            <h4 className="text-2xl">Profile</h4>
            <div className="flex gap-2 items-center mt-2 fw-400">
              <p className="cursor-pointer text-primary">Dashboard</p>
              <AiFillCaretRight className="text-gray-400" />
              <p className="text-gray-400">Requests</p>
            </div>
          </div>
          {edit ? (
            <div className="flex gap-4 items-center">
              <Button
                type="filled"
                state="black-outlined"
                className="px-4 flex items-center gap-1 text-[black] bg-transparent h-10"
                onClick={() => {
                  setEdit(false);
                  getData();
                }}
              >
                <img src={Icons.RECENT} className="h-4 w-4" alt="Reset" /> Reset
              </Button>
              <Button
                onClick={handleUpdate}
                type="filled"
                state="success"
                className="px-8 h-10"
              >
                Save
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                setEdit(true);
              }}
              state="success"
              type="filled"
            >
              <div className="flex gap-1 items-center">
                <img className="h-4 w-4" src={Icons.PENCIL} alt="Edit" />
                Edit
              </div>
            </Button>
          )}
        </div>

        <div className="grid grid-cols-4 gap-6 px-6 pb-6">
          {/* Left */}
          <div className="col-span-3 flex flex-col gap-6">
            {/* Basic Information */}
            <div className="bg-[white] p-6 rounded-[8px] border">
              <h4 className="text-lg">Basic Information</h4>
              <div className="mt-3.5">
                <label htmlFor="" className="text-black_300">
                  Cafe/Restaurant/venue name
                </label>
                <Input
                  className="mt-1 text-black_300"
                  placeholder="Eg:. Oia Cafe"
                  value={data?.name}
                  name="name"
                  onChange={handleChange}
                  disabled={true}
                />
              </div>
              <div className="mt-3.5">
                <label htmlFor="" className="text-black_300">
                  Address
                </label>
                <Input
                  className="mt-1 text-black_300"
                  placeholder="Eg:. Oia, Hennur Main Road, New Airport Rd, opposite Mantri Webcity, Visthar, Bengaluru, Karnataka 560077"
                  value={data?.address}
                  name="address"
                  onChange={handleChange}
                  disabled={true}
                />
              </div>
              <div className="mt-3.5">
                <label htmlFor="" className="text-black_300">
                  Location
                </label>
                <Input
                  className="mt-1 text-black_300"
                  placeholder="Paste the Google maps link here"
                  value={data?.location}
                  name="location"
                  onChange={handleChange}
                  disabled={!edit}
                />
              </div>
              <div className="mt-3.5">
                <label htmlFor="" className="text-black_300">
                  Description
                </label>
                <InputArea
                  rows={5}
                  className="mt-1 text-black_300"
                  placeholder="Eg:. Oia Cafe is a beautiful cafe located in the heart of the city. We serve the best coffee and snacks in town."
                  value={data?.description}
                  name="description"
                  onChange={handleChange}
                  disabled={!edit}
                />
              </div>
            </div>

            {/* Timings */}
            <div className="bg-[white] p-6 rounded-[8px] border">
              <h4 className="text-lg">Timings</h4>
              <div className="mt-3.5 grid grid-cols-3 gap-3.5">
                <div className="col-span-1">
                  <h6 className="text-black_300">Open on</h6>
                  <div className="mt-3 flex justify-between">
                    <div className="flex flex-col gap-3">
                      <div className="flex gap-2 text-black_300">
                        <CheckBox
                          className={`${!edit ? "cursor-not-allowed" : ""} `}
                          checked={data?.open_on?.some(
                            (s: string) => s === "MONDAY"
                          )}
                          onChange={(e: any) => {
                            if (!edit) return;
                            console.log(e.target.checked);
                            if (e.target.checked) {
                              const arr = data?.open_on || [];
                              arr.push("MONDAY");
                              setData((prev: any) => ({
                                ...prev,
                                open_on: arr,
                              }));
                            } else {
                              setData((prev: any) => ({
                                ...prev,
                                open_on: prev.open_on.filter(
                                  (s: string) => s !== "MONDAY"
                                ),
                              }));
                            }
                          }}
                        />
                        Monday
                      </div>
                      <div className="flex gap-2 text-black_300">
                        <CheckBox
                          checked={data?.open_on?.some(
                            (s: string) => s === "TUESDAY"
                          )}
                          onChange={(e: any) => {
                            if (!edit) return;
                            console.log(e.target.checked);
                            if (e.target.checked) {
                              const arr = data?.open_on || [];
                              arr.push("TUESDAY");
                              setData((prev: any) => ({
                                ...prev,
                                open_on: arr,
                              }));
                            } else {
                              setData((prev: any) => ({
                                ...prev,
                                open_on: prev.open_on.filter(
                                  (s: string) => s !== "TUESDAY"
                                ),
                              }));
                            }
                          }}
                        />
                        Tuesday
                      </div>
                      <div className="flex gap-2 text-black_300">
                        <CheckBox
                          checked={data?.open_on?.some(
                            (s: string) => s === "WEDNSDAY"
                          )}
                          onChange={(e: any) => {
                            if (!edit) return;
                            console.log(e.target.checked);
                            if (e.target.checked) {
                              const arr = data?.open_on || [];
                              arr.push("WEDNSDAY");
                              setData((prev: any) => ({
                                ...prev,
                                open_on: arr,
                              }));
                            } else {
                              setData((prev: any) => ({
                                ...prev,
                                open_on: prev.open_on.filter(
                                  (s: string) => s !== "WEDNSDAY"
                                ),
                              }));
                            }
                          }}
                        />
                        Wednsday
                      </div>
                      <div className="flex gap-2 text-black_300">
                        <CheckBox
                          checked={data?.open_on?.some(
                            (s: string) => s === "THURSDAY"
                          )}
                          onChange={(e: any) => {
                            if (!edit) return;
                            console.log(e.target.checked);
                            if (e.target.checked) {
                              const arr = data?.open_on || [];
                              arr.push("THURSDAY");
                              setData((prev: any) => ({
                                ...prev,
                                open_on: arr,
                              }));
                            } else {
                              setData((prev: any) => ({
                                ...prev,
                                open_on: prev.open_on.filter(
                                  (s: string) => s !== "THURSDAY"
                                ),
                              }));
                            }
                          }}
                        />
                        Thursday
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="flex gap-2 text-black_300">
                        <CheckBox
                          checked={data?.open_on?.some(
                            (s: string) => s === "FRIDAY"
                          )}
                          onChange={(e: any) => {
                            if (!edit) return;
                            console.log(e.target.checked);
                            if (e.target.checked) {
                              const arr = data?.open_on || [];
                              arr.push("FRIDAY");
                              setData((prev: any) => ({
                                ...prev,
                                open_on: arr,
                              }));
                            } else {
                              setData((prev: any) => ({
                                ...prev,
                                open_on: prev.open_on.filter(
                                  (s: string) => s !== "FRIDAY"
                                ),
                              }));
                            }
                          }}
                        />
                        Friday
                      </div>
                      <div className="flex gap-2 text-black_300">
                        <CheckBox
                          checked={data?.open_on?.some(
                            (s: string) => s === "SATURDAY"
                          )}
                          onChange={(e: any) => {
                            if (!edit) return;
                            console.log(e.target.checked);
                            if (e.target.checked) {
                              const arr = data?.open_on || [];
                              arr.push("SATURDAY");
                              setData((prev: any) => ({
                                ...prev,
                                open_on: arr,
                              }));
                            } else {
                              setData((prev: any) => ({
                                ...prev,
                                open_on: prev.open_on.filter(
                                  (s: string) => s !== "SATURDAY"
                                ),
                              }));
                            }
                          }}
                        />
                        Saturday
                      </div>
                      <div className="flex gap-2 text-black_300">
                        <CheckBox
                          checked={data?.open_on?.some(
                            (s: string) => s === "SUNDAY"
                          )}
                          onChange={(e: any) => {
                            if (!edit) return;
                            console.log(e.target.checked);
                            if (e.target.checked) {
                              const arr = data?.open_on || [];
                              arr.push("SUNDAY");
                              setData((prev: any) => ({
                                ...prev,
                                open_on: arr,
                              }));
                            } else {
                              setData((prev: any) => ({
                                ...prev,
                                open_on: prev.open_on.filter(
                                  (s: string) => s !== "SUNDAY"
                                ),
                              }));
                            }
                          }}
                        />
                        Sunday
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-1">
                  <label htmlFor="" className="text-black_300">
                    Opening time
                  </label>
                  <div className="mt-1">
                    <TImePicker
                      className="w-full px-3 py-2 h-10"
                      disabled={!edit}
                      placeholder="Select the opening time"
                      defaultValue={dayjs("00:00", "HH:mm")}
                      value={data?.open_time ? dayjs(data?.open_time) : null}
                      onChange={(e: any, timeString: any) => {
                        setData((prev: any) => ({
                          ...prev,
                          open_time: e,
                        }));
                      }}
                      onSelect={(e: any) => {
                        setData((prev: any) => ({
                          ...prev,
                          open_time: e,
                        }));
                      }}
                      name="open_time"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <label htmlFor="" className="text-black_300">
                    Closing time
                  </label>
                  <div className="mt-1">
                    <TImePicker
                      className="w-full px-3 py-2 h-10"
                      disabled={!edit}
                      placeholder="Select the closing time"
                      value={data?.close_time ? dayjs(data?.close_time) : null}
                      onChange={(e: any, timeString: any) => {
                        console.log(e, timeString);

                        setData((prev: any) => ({
                          ...prev,
                          close_time: e,
                        }));
                      }}
                      onSelect={(e: any) => {
                        setData((prev: any) => ({
                          ...prev,
                          close_time: e,
                        }));
                      }}
                      name="close_time"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* More Details */}
            <div className="bg-[white] p-6 rounded-[8px] border">
              <h4 className="text-lg">More details</h4>
              <div className="mt-3.5">
                <label htmlFor="" className="text-black_300">
                  Price for two
                </label>
                <div className="mt-1">
                  <InputNumber
                    className="rounded-[8px]  px-3 py-2 w-full text-black_300"
                    type="number"
                    prefix="₹ |"
                    placeholder=""
                    value={data?.price_for_two}
                    onChange={(e: any) => {
                      setData((prev: any) => ({
                        ...prev,
                        price_for_two: e,
                      }));
                    }}
                    disabled={!edit}
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-3.5">
                <div className="mt-3.5">
                  <label htmlFor="" className="text-black_300">
                    Contact details
                  </label>
                  <div className="mt-1">
                    <InputNumber
                      className="rounded-[8px]  px-3 py-2 w-full text-black_300"
                      type="number"
                      prefix="+ 91 | "
                      placeholder=""
                      value={data?.phone}
                      disabled={!edit}
                      onChange={(e: any) => {
                        setData((prev: any) => ({
                          ...prev,
                          phone: e,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="mt-3.5">
                  <label htmlFor="" className="text-black_300">
                    Alternate contact
                  </label>
                  <div className="mt-1">
                    <InputNumber
                      className="rounded-[8px]  px-3 py-2 w-full text-black_300"
                      type="number"
                      prefix="+ 91 | "
                      placeholder=""
                      value={data?.alternate_phone}
                      disabled={!edit}
                      onChange={(e: any) => {
                        setData((prev: any) => ({
                          ...prev,
                          alternate_phone: e,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Media */}
            <div className="bg-[white] p-6 rounded-[8px] border">
              <h4 className="text-lg">Media</h4>

              <div className="mt-3.5 flex flex-col gap-8">
                <div>
                  <h6 className="text-black_300">Ambience Images</h6>
                  <div
                    className={`mt-1  ${
                      !edit ? "cursor-not-allowed pointer-events-none" : ""
                    }`}
                  >
                    <Upload
                      onChange={(e) => {
                        handleImageUpload("media_ambience_images", e);
                      }}
                    />
                  </div>
                  <div className="mt-4 flex gap-4">
                    {data?.media_ambience_images?.map(
                      (item: string, index: number) => (
                        <div
                          key={"ambience_" + index}
                          className="h-[90px] w-[90px] rounded-[8px] relative overflow-hidden flex items-center justify-center uploaded-img "
                        >
                          {edit && (
                            <div
                              className="h-10 w-10 rounded-lg bg-error_light z-50 relative p-2 uploaded-img-icon cursor-pointer"
                              onClick={() => {
                                if (!edit)
                                  return Toast.Error(
                                    "You can't delete images in non-edit mode"
                                  );
                                setData((prev: any) => ({
                                  ...prev,
                                  media_ambience_images:
                                    prev.media_ambience_images.filter(
                                      (s: string) => s !== item
                                    ),
                                }));
                              }}
                            >
                              <img
                                src={Icons.TRASH_RED}
                                className="w-full"
                                alt=""
                              />
                            </div>
                          )}
                          <img
                            src={item}
                            className="absolute z-10 h-full w-full"
                            alt=""
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div>
                  <h6 className="text-black_300">Menu Images</h6>
                  <div
                    className={`mt-1  ${
                      !edit ? "cursor-not-allowed pointer-events-none" : ""
                    }`}
                  >
                    <Upload
                      onChange={(e) => {
                        handleImageUpload("media_menu_images", e);
                      }}
                    />
                  </div>
                  <div className="mt-4 flex gap-4">
                    {data?.media_menu_images?.map(
                      (item: string, index: number) => (
                        <div
                          key={"ambience_" + index}
                          className="h-[90px] w-[90px] rounded-[8px] relative overflow-hidden flex items-center justify-center uploaded-img cursor-pointer"
                        >
                          {edit && (
                            <div
                              className="h-10 w-10 rounded-lg bg-error_light z-50 relative p-2 uploaded-img-icon"
                              onClick={() => {
                                if (!edit)
                                  return Toast.Error(
                                    "You can't delete images in non-edit mode"
                                  );
                                setData((prev: any) => ({
                                  ...prev,
                                  media_menu_images:
                                    prev.media_menu_images.filter(
                                      (s: string) => s !== item
                                    ),
                                }));
                              }}
                            >
                              <img
                                src={Icons.TRASH_RED}
                                className="w-full"
                                alt=""
                              />
                            </div>
                          )}
                          <img
                            src={item}
                            className="absolute z-10 h-full w-full"
                            alt=""
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div>
                  <h6 className="text-black_300">Food Images</h6>
                  <div
                    className={`mt-1  ${
                      !edit ? "cursor-not-allowed pointer-events-none" : ""
                    }`}
                  >
                    <Upload
                      onChange={(e) => {
                        handleImageUpload("media_food_images", e);
                      }}
                    />
                  </div>
                  <div className="mt-4 flex gap-4">
                    {data?.media_food_images?.map(
                      (item: string, index: number) => (
                        <div
                          key={"ambience_" + index}
                          className="h-[90px] w-[90px] rounded-[8px] relative overflow-hidden flex items-center justify-center uploaded-img cursor-pointer"
                        >
                          {edit && (
                            <div
                              className="h-10 w-10 rounded-lg bg-error_light z-50 relative p-2 uploaded-img-icon"
                              onClick={() => {
                                if (!edit)
                                  return Toast.Error(
                                    "You can't delete images in non-edit mode"
                                  );
                                setData((prev: any) => ({
                                  ...prev,
                                  media_food_images:
                                    prev.media_food_images.filter(
                                      (s: string) => s !== item
                                    ),
                                }));
                              }}
                            >
                              <img
                                src={Icons.TRASH_RED}
                                className="w-full"
                                alt=""
                              />
                            </div>
                          )}
                          <img
                            src={item}
                            className="absolute z-10 h-full w-full"
                            alt=""
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                {/* <div>
                  <h6 className="text-black_300">Video</h6>
                  <div className=" mt-1">
                    <Upload
                      onChange={(e) => {
                        handleImageUpload("media_videos", e);
                      }}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* Right */}
          <div className="col-span-1">
            {/* Category */}
            <div className="bg-[white] p-6 rounded-[8px] border">
              <h4 className="text-lg">Category</h4>
              <p className="mt-3.5 text-sm text-black_300">
                Select the category
              </p>
              <div className="flex flex-wrap gap-2 mt-1">
                {PROFILE_CATEGORY.map((item, index) => (
                  <SelectTag
                    key={"category_" + index}
                    disabled={!edit}
                    label={item.label}
                    value={data?.category?.some((s: any) => s === item.value)}
                    onChange={() => {
                      if (!edit) return;
                      if (data?.category?.some((s: any) => s === item.value)) {
                        setData((prev: any) => ({
                          ...prev,
                          category: prev?.category.filter(
                            (s: any) => s !== item.value
                          ),
                        }));
                      } else {
                        const arr = data?.category || [];
                        arr.push(item.value);
                        setData((prev: any) => ({
                          ...prev,
                          category: arr,
                        }));
                      }
                    }}
                  />
                ))}
              </div>
              <p className="mt-6 text-sm text-black_300">Cusines</p>
              <div className="flex flex-wrap gap-2 mt-1">
                {PROFILE_CATEGORY_CUISINS.map((item, index) => (
                  <SelectTag
                    key={"cuisins_" + index}
                    disabled={!edit}
                    label={item.label}
                    value={data?.cuisins?.some((s: any) => s === item.value)}
                    onChange={() => {
                      if (!edit) return;
                      if (data?.cuisins?.some((s: any) => s === item.value)) {
                        setData((prev: any) => ({
                          ...prev,
                          cuisins: prev.cuisins.filter(
                            (s: any) => s !== item.value
                          ),
                        }));
                      } else {
                        const arr = data?.cuisins || [];
                        arr.push(item.value);
                        setData((prev: any) => ({
                          ...prev,
                          cuisins: arr,
                        }));
                      }
                    }}
                  />
                ))}
              </div>
            </div>

            {/* Features */}
            <div className="bg-[white] p-6 rounded-[8px] border mt-6">
              <h4 className="text-lg">Features</h4>
              <div className="flex flex-wrap gap-2 mt-3.5">
                {PROFILE_CATEGORY_FEATURES.map((item, index) => (
                  <SelectTag
                    key={"categoryfeatures_" + index}
                    disabled={!edit}
                    label={item.label}
                    value={data?.categoryfeatures?.some(
                      (s: any) => s === item.value
                    )}
                    onChange={() => {
                      if (!edit) return;
                      if (
                        data?.categoryfeatures?.some(
                          (s: any) => s === item.value
                        )
                      ) {
                        setData((prev: any) => ({
                          ...prev,
                          categoryfeatures: prev.categoryfeatures.filter(
                            (s: any) => s !== item.value
                          ),
                        }));
                      } else {
                        const arr = data?.categoryfeatures || [];
                        arr.push(item.value);
                        setData((prev: any) => ({
                          ...prev,
                          categoryfeatures: arr,
                        }));
                      }
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white p-4 border w-full sticky bottom-0 z-[999]">
          <div className="flex items-center gap-4 ">
            Profile completion
            <span
              className={`px-2.5 py-1 rounded-lg ${
                profilePercentage() < 70
                  ? "bg-error_light text-error  "
                  : profilePercentage() >= 70 && profilePercentage() <= 90
                  ? "bg-secondary_light text-secondary "
                  : " text-primary bg-primary_light"
              }`}
            >
              {profilePercentage()}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
