import React, { useEffect, useState } from "react";
import Button from "../../components/Partial/Button";
import { Icons } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { OffersApi } from "../../services/OffersAPI";
import { toast } from "react-toastify";
import Nodata from "../../components/Partial/Nodata";
import { BREADCRUMB_OPTION } from "../../utils/commonUtils";
import BreadCrumb from "../../components/Partial/BreadCrumb";
import { ROUTES } from "../../router/routerConfig";
import { Toast } from "../../wrapper/ToastWrapper";
import axios from "axios";

type Props = {};

const Offers = (props: Props) => {
  const navigate = useNavigate();

  const [data, setData] = useState<any>([]);

  const getData = async () => {
    try {
      const res = await OffersApi.GetOffers();
      if (res) {
        setData(res.data);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  const handleDelete = async (id: any) => {
    try {
      const res = await OffersApi.DeleteOffer(id);
      if (res) {
        toast.success("Offer deleted successfully");
        getData();
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {/* Breadcrumb */}
      <div className="flex justify-between items-end">
        <div>
          <h4 className="text-2xl">Offers & discounts</h4>

          <BreadCrumb items={BREADCRUMB_OPTION.OFFERS} />
        </div>
        <Button
          state="success"
          type="filled"
          onClick={() => {
            navigate(ROUTES.ADD_OFFERS);
          }}
        >
          <div className="flex gap-1 items-center">
            <img className="h-4 w-4" src={Icons.ADD_WHITE} alt="Add Icon"/>
            Add new offer
          </div>
        </Button>
      </div>

      <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-6">
        {data?.length > 0 ? (
          data?.map((item: any, index: number) => (
            <div
              className="col-span-1 flex cursor-pointer"
              onClick={() => {
                navigate(`${ROUTES.OFFER}/${item._id}`);
              }}
            >
              <div className="w-[64px] bg-primary border-l-4 border-dotted border-bg_main"></div>
              {/* <img src={IMAGES.RECIEPT_SVG} className="w-full h-full" alt="" /> */}
              <div className="grow bg-white border border-primary py-7 px-5">
                <div className="flex justify-between items-start">
                  <div>
                    <h4 className="text-xl font-semibold">
                      {item?.coupon_code}
                    </h4>
                    <h6 className="text-xs text-primary">{item?.title}</h6>
                  </div>
                  <img
                    src={Icons.TRASH_RED}
                    onClick={() => {
                      handleDelete(item._id);
                    }}
                    alt=""
                    className="h-5 w-5 mt-1 cursor-pointer"
                  />
                </div>
                <div className="w-full border border-dashed h-[1px] my-4"></div>
                <p className="text-black_300 text-sm">{item?.description}</p>
                <p className="text-black_300 text-sm mt-5">
                  Max. discount: {item?.max_discount}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-3 flex justify-center items-center mt-10">
            <Nodata label="No active offers found!"></Nodata>
          </div>
        )}
      </div>
    </div>
  );
};

export default Offers;
