import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../components/Partial/BreadCrumb";
import { BREADCRUMB_OPTION, FILTER_TABS } from "../../../utils/commonUtils";
import FilterTabs from "../../../components/Partial/FilterTabs";
import Table from "../../../components/Table/Table";
import { IMAGES } from "../../../assets/images";
import moment from "moment";
import { AdminApi } from "../../../services/AdminAPI";

type Props = {};

const Users = (props: Props) => {
  const [filters, setFilters] = useState<any>({
    date: "all",
  });

  const [data, setData] = useState([]);

  const columns = [
    {
      title: "User ID",
      dataIndex: "_id",
      key: "booking_id",
    },
    {
      title: "User Profile",
      dataIndex: "user",
      render: (d: any, data: any) => (
        <div className="flex gap-3 items-center">
          <div className="flex ">
            <img
              src={data?.photos?.[0]?.photoUrl || IMAGES.BannerAuth}
              className="rounded-full h-11 w-11 border"
              alt=""
            />
          </div>
          <div>
            <h4 className="text-sm">
              {data?.name} / {moment().diff(moment(data?.dob), "years")}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: "Contact",
      dataIndex: "phoneno",
      key: "phoneno",
      render: (d: any) => (
        <div>
          <h4 className="text-sm">{d}</h4>
        </div>
      ),
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
      render: (d: any) => (
        <div>
          <h4 className="text-sm">{d || "Not Mentioned"}</h4>
        </div>
      ),
    },
    {
      title: "Member Since",
      dataIndex: "date",
      key: "date",
      render: (_: any, d: any) => (
        <>{moment(d.createdAt).format("Do MMM YYYY")}</>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (d: any) => d || "Not Mentioned",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      render: (d: any) => d || "Not Mentioned",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (d: any) => d || "Not Mentioned",
    },
  ];

  const getData = async () => {
    try {
      const params = {
        date: filters.date,
      };
      const res = await AdminApi.GetAllUsers(params);
      if (res) {
        setData(res.data);
      } else {
        setData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [filters]);

  return (
    <div>
      <h4 className="text-2xl">REQUESTS</h4>

      {/* Breadcrumb */}
      <BreadCrumb items={BREADCRUMB_OPTION.ADMIN.USERS} />

      <div className="mt-9 flex gap-6 items-center">
        <FilterTabs
          filters={FILTER_TABS.USERS}
          value={filters.date}
          onChange={(e) => {
            setFilters({ ...filters, date: e });
          }}
        />
      </div>

      <div className="mt-6">
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default Users;
