import sidebarDashboard from "./dashboard.svg";
import sidebarCross from "./cross.svg";
import sidebarHamburger from "./hamburger.svg";
import sidebarrequests from "./reqests_main.svg";
import sidebarProfile from "./profile.svg";
import sidebarOffers from "./offers.svg";
import sidebarSettings from "./settings.svg";
import dashboardTotalBookings from "./dashboard_total_bookings.svg";
import dashboardTotalEarnings from "./dashboard_total_earnings.svg";
import dashboardAvgBillVal from "./dashboard_avg_bill_val.svg";
import dashboardOfferRedemption from "./dashboard_offer_redemption.svg";
import threeDots from "./three_dots.svg";
import trash from "./trash.svg";
import recent from "./recent.svg";
import upload from "./upload.svg";
import trashRed from "./trash-red.svg";
import cross from "./cross.svg";
import crossBlack from "./cross-black.svg";
import add from "./add.svg";
import addWhitw from "./add-white.svg";
import pencil from "./pencil.svg";
import arrowAngleRight from "./arrow_angle_right.svg";
import crossRedBg from "./cross-red-bg-white-font.svg";
import greenBookmark from "./green-bookmark.svg";
import CaretDown from "./caret-down.svg";
import ToastSuccess from "./toast-success.svg";
import ToastError from "./toast-error.svg";
import CrossGreen from "./cross-green.svg";
import CrossRed from "./cross-red.svg";
import LogoWhiteNoBg from "./logo-white-no-bg.svg";
import LogoWhiteNoBgSmall from "./logo-white-small.svg";

import AdminDashboardTotalUsers from "./admin-dahsboard-total-users.svg";
import AdminDashboardActiveUsers from "./admin-dashboard-active-users.svg";
import HeartGreen from "./heart-green.svg";
import MaleVsFemals from "./malevsfemal.svg";
import AdminDashboardCafeCOunt from "./admin-dashbboard-cafe-count.svg";
import AdminDashboardCompletedMeetings from "./admin-dashboard-completed-meetings.svg";
import AdminDashboardIncompletedMeetings from "./admin-dashboard-incomplete-meetings.svg";

import AdminVenueFavourite from "./admin-venue-favourite.svg";
import AdminVenueFavouriteSelected from "./admin-venue-favourite-seleted.svg";
import AdminVenueEdit from "./admin-venue-edit.svg";
import AdminVenueDelete from "./admin-venue-delete.svg";

export const Icons = {
  SIDEBAR_DASHBOARD: sidebarDashboard,
  SIDEBAR_CROSS: sidebarCross,
  SIDEBAR_HAMBURGER: sidebarHamburger,
  SIDEBAR_REQUESTS: sidebarrequests,
  SIDEBAR_PROFILE: sidebarProfile,
  SIDEBAR_SETTINGS: sidebarSettings,
  SIDEBAR_OFFERS: sidebarOffers,
  DASHBOARD_TOTAL_BOOKINGS: dashboardTotalBookings,
  DASHBOARD_TOTAL_EARNINGS: dashboardTotalEarnings,
  DASHBOARD_AVG_BILL_VAL: dashboardAvgBillVal,
  DASHBOARD_OFFER_REDEMPTION: dashboardOfferRedemption,
  THREE_DOTS: threeDots,
  TRASH: trash,
  RECENT: recent,
  UPLOAD: upload,
  TRASH_RED: trashRed,
  CROSS: cross,
  CROSS_BLACK: crossBlack,
  ADD: add,
  PENCIL: pencil,
  ARROW_ANGLE_RIGHT: arrowAngleRight,
  ADD_WHITE: addWhitw,
  CROSS_RED_BG: crossRedBg,
  GREEN_BOOKMARK: greenBookmark,
  CARET_DOWN: CaretDown,
  TOAST_SUCCESS: ToastSuccess,
  TOAST_ERROR: ToastError,
  CROSS_GREEN: CrossGreen,
  CROSS_RED: CrossRed,
  LOGO_WHITE_NO_BG: LogoWhiteNoBg,
  LOGO_WHITE_NO_BG_SMALL: LogoWhiteNoBgSmall,

  ADMIN_DASHBOARD_TOTAL_USERS: AdminDashboardTotalUsers,
  ADMIN_DASHBOARD_ACTIVE_USERS: AdminDashboardActiveUsers,
  HEART_GREEN: HeartGreen,
  MALE_VS_FEMALE: MaleVsFemals,
  ADMIN_DASHBOARD_CAFE_COUNT: AdminDashboardCafeCOunt,
  ADMIN_DASHBOARD_COMPLETED_MEETINGS: AdminDashboardCompletedMeetings,
  ADMIN_DASHBOARD_INCOMPLETED_MEETINGS: AdminDashboardIncompletedMeetings,

  ADMIN_VENUE_FAVOURITE: AdminVenueFavourite,
  ADMIN_VENUE_FAVOURITE_SELECTED: AdminVenueFavouriteSelected,
  ADMIN_VENUE_EDIT: AdminVenueEdit,
  ADMIN_VENUE_DELETE: AdminVenueDelete,
};
