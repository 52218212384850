import React from "react";
import { Modal as Mod } from "antd";
import { Icons } from "../../assets/icons";

type Props = {
  children?: React.ReactNode;
  open?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
  width?: number | string;
};

const Modal = ({ children, open, onCancel, onOk, width }: Props) => {
  return (
    <Mod
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      width={width}
      title={null}
      closeIcon={<img src={Icons.CROSS_BLACK} alt="close" />}
      footer={null}
      wrapClassName="flex justify-center items-center"
      className="top-9"
    >
      {children}
    </Mod>
  );
};

export default Modal;
