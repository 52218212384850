import React from "react";
import { Line } from "@ant-design/plots";

interface Props {
  data: any;
}

const Overview = ({ data }: Props) => {
  //   useEffect(() => {
  //     asyncFetch();
  //   }, []);

  //   const asyncFetch = () => {
  //     fetch(
  //       "https://gw.alipayobjects.com/os/bmw-prod/55424a73-7cb8-4f79-b60d-3ab627ac5698.json"
  //     )
  //       .then((response) => response.json())
  //       .then((json) => setData(json))
  //       .catch((error) => {
  //         console.log("fetch data failed", error);
  //       });
  //   };
  const config = {
    data,
    xField: "month",
    yField: "value",
    seriesField: "category",
    xAxis: {
      //   type: "time",
    },
    // yAxis: {
    //   label: {
    //     // 数值格式化为千分位
    //     formatter: (v: any) =>
    //       `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
    //   },
    // },
  };

  return <Line {...config} />;
};

export default Overview;
