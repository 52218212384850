import React from "react";
import { IMAGES } from "../../assets/images";
import { Form } from "antd";
import OtpInput from "../../components/FormFileds/OtpInput";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthApi } from "../../services/AuthAPI";
import { Toast } from "../../wrapper/ToastWrapper";
import Loader from "../../components/Partial/Loader";
import { ROUTES, RouteKeys } from "../../router/routerConfig";
import { Icons } from "../../assets/icons";
import axios from "axios";

type Props = {};

const VerifyOtp = (props: Props) => {
  const [otp, setOtp] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();
  const { mobile } = useLocation().state;

  const handleSubmit = async () => {
    try {
      const res: any = await AuthApi.PostLogin({ phone: mobile });
      if (res) {
        Toast.Success("Login Success");

        sessionStorage.setItem("token", res.token);
        sessionStorage.setItem("role", res.user.role);
        sessionStorage.setItem("cafe_id", res.user.cafe_id);
        sessionStorage.setItem("user", JSON.stringify(res.user));
        setTimeout(() => {
          if (res?.user?.tab) {
            let tab: RouteKeys = res.user.tab as RouteKeys;
            // navigate(ROUTES[tab]);
            navigate(ROUTES[tab] as string);
          } else {
            navigate(ROUTES.DASHBOARD);
          }
        }, 500);
      }
      setLoading(false);
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          setLoading(false);
          return;
        }
      }
      Toast.Error("Something went wrong");
      setLoading(false);
    }
  };

  const verifyOtp = () => {
    if (otp.length === 6) {
      setLoading(true);
      let finalOtp = otp.join("");

      // verifu otp
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(finalOtp)
        .then((result: any) => {
          // User signed in successfully.
          let user = result.user;
          console.log(user);
          handleSubmit();
          // ...
        })
        .catch((error: any) => {
          // User couldn't sign in (bad verification code?)
          // ...
          console.log(error);
          Toast.Error("Invalid OTP");
          setLoading(false);
        });
    }
  };

  return (
    <div className="">
      {loading ? <Loader /> : null}
      <div className="grid grid-cols-2 gap-8 min-h-screen max-h-screen">
        <div className="col-span-1 lg:block hidden max-h-screen">
          <div
            className="h-[100%] w-[100%]"
            style={{
              backgroundImage: `url(${IMAGES.BannerAuth})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
        <div className="lg:col-span-1 col-span-2 flex justify-between items-center flex-col py-[40px]">
          <div></div>
          <div className="sm:w-[480px] w-[100%] p-5">
            <div className="w-[110px] h-[110px] bg-primary rounded-full p-8">
              <img src={Icons.LOGO_WHITE_NO_BG} className="w-full" alt="Logo" />
            </div>
            <h4 className="text-[36px] mt-8">OTP Verification</h4>
            <p className="text-[20px] mt-4">
              Enter the 6-digit OTP we've sent you to your mobile number ending
              with ****{String(mobile)?.slice(-4) || ""}.
            </p>
            <Form className="mt-[38px]">
              <Form.Item name={"otp"}>
                <OtpInput
                  valye={otp}
                  onChange={(value: any) => setOtp(value)}
                  autoFocus
                  inputType="numeric"
                  length={6}
                  inputStyle={{
                    width: "90px",
                    maxWidth: "90px",
                    height: "90px",
                    maxHeight: "90px",
                  }}
                />
              </Form.Item>
            </Form>
            <button
              onClick={verifyOtp}
              className="w-full py-2 px-4 bg-[#292929] text-[white] rounded-[16px] h-[56px] mt-[38px]"
            >
              Continue
            </button>
            {/* <p className="mt-[32px]">
              Didn’t receive any code?&nbsp;
              <a href="#" className="underline text-[green]">
                Resend
              </a>
              &nbsp;in&nbsp;
              <span className="text-[green]">12 sec</span>
            </p> */}
          </div>
          <div className=" w-[100%] self-end">
            <p className="text-center text-[#959CB6] px-5">
              © 2023 ALL RIGHTS RESERVED CONGLE INDIA PVT. LTD.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
