import BannerAuth from "./banner/auth.png";
import RoundLogo from "./logo.svg";
import dashboardBG from "./dashboard_bg.png";
import UploadSample1 from "./upload-sample1.png";
import reciept from "./reciept.png";
import recieptSvg from "./reciept.svg";
import noData from "./no-data.svg";

export const IMAGES = {
  BannerAuth,
  ROUND_LOGO: RoundLogo,
  DASHBOARD_BG: dashboardBG,
  UPLOAD_SAMPLE1: UploadSample1,
  RECIEPT: reciept,
  RECIEPT_SVG: recieptSvg,
  NO_DATA: noData,
};
