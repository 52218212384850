import React, { useEffect } from "react";
import { IMAGES } from "../../../assets/images";
import Input from "../../../components/FormFileds/Input";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "../../../services/AuthAPI";
import { toast } from "react-toastify";
import { Icons } from "../../../assets/icons";
import { Toast } from "../../../wrapper/ToastWrapper";
import { ROUTES } from "../../../router/routerConfig";

type Props = {};

const Login = (props: Props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!email || !password) {
      return toast.error("Please enter email and password");
    }
    try {
      const res = await AuthApi.PostLogin({ email, password, role: "admin" });
      if (res) {
        Toast.Success("Login Success");

        sessionStorage.setItem("token", res.token);
        sessionStorage.setItem("role", res.user.role);
        navigate(ROUTES.ADMIN.DASHBOARD);
      }
    } catch (err: any) {
      console.log(err);

      toast.error(err?.response?.data?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    sessionStorage.clear();
  }, []);
  return (
    <div className="">
      <div className="grid grid-cols-2 gap-8 min-h-screen max-h-screen">
        <div className="col-span-1 lg:block hidden max-h-screen">
          <div
            className="h-[100%] w-[100%]"
            style={{
              backgroundImage: `url(${IMAGES.BannerAuth})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
        </div>
        <div className="lg:col-span-1 col-span-2 flex justify-between items-center flex-col py-[40px]">
          <div></div>
          <div className="sm:w-[480px] w-[100%] p-5">
            <div className="w-[110px] h-[110px] bg-primary rounded-full p-8">
              <img src={Icons.LOGO_WHITE_NO_BG} className="w-full" alt="Logo" />
            </div>
            <h4 className="text-[36px] mt-8">GET STARTED</h4>
            <p className="text-[20px] mt-4">
              Log in to your account and unlock a world of new connections, and
              earnings
            </p>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="h-[56px] mt-[46px] rounded-[16px]"
            />
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              className="h-[56px] mt-[46px] rounded-[16px]"
              type="password"
            />
            <button
              onClick={() => {
                if (email && password) handleSubmit();
              }}
              className={`w-full py-2 px-4 bg-[#292929] text-[white] rounded-[16px] h-[56px] mt-[35px] ${
                !(email && password)
                  ? "cursor-not-allowed opacity-50"
                  : "cursor-pointer hover:bg-[#1E1E1E] transition-all duration-300 ease-in-out shadow-md"
              }`}
            >
              Continue
            </button>
          </div>
          <div className=" w-[100%] self-end">
            <p className="text-center text-[#959CB6] px-5">
              © 2023 ALL RIGHTS RESERVED CONGLE INDIA PVT. LTD.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
