import React from "react";
import { Icons } from "../../assets/icons";
import Button from "../Partial/Button";

type Props = {
  onCancel?: () => void;
  handleSubmit?: () => void;
  title: string;
  description: string;
  submitText: string;
  cancelText: string;
};

const WarningPop = (props: Props) => {
  return (
    <div className="pt-[52px] flex flex-col items-center justify-center">
      <div className="p-6 rounded-full bg-error_light">
        <img src={Icons.CROSS_RED_BG} alt="" />
      </div>
      <div className="mt-3">
        <h4 className="text-center text-xl font-600">{props.title}</h4>
        <p className="text-center font-400 mt-2">{props.description}</p>
      </div>
      <div className="flex justify-between w-full mt-8">
        <Button
          onClick={props.onCancel}
          state="black-outline"
          className="bg-transparent"
        >
          {props.cancelText}
        </Button>
        <Button onClick={props.handleSubmit} state="danger" type="filled">
          {props.submitText}
        </Button>
      </div>
    </div>
  );
};

export default WarningPop;
