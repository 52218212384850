import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const AdminApi = {
  GetAllUsers: async (params, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/admin/users",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  GetAllCafes: async (params, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/admin/cafe",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  GetDashbaordStat: async (params, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/admin/dashboard",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      params,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  PostAddCafe: async (data, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/admin/cafe",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  GetCafeById: async (id, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: `/admin/cafe/get-by-id/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  UpdateCafeById: async (id, data, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: `/admin/cafe/update-by-id/${id}`,
      method: "PUT",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  DeleteCafeById: async (id, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: `/admin/cafe/delete-by-id/${id}`,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  handleCafeFavouriteById: async (id, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: `/admin/cafe/handle-favourite/${id}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(AdminApi);
