import React, { useContext } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { GoBellFill } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { userContext } from "../../utils/contexts";
import { UserContextInterface } from "../../interfaces";
import { Dropdown, MenuProps } from "antd";
import Button from "./Button";
import { ROUTES } from "../../router/routerConfig";
import { handleLogout } from "../../utils/reUsableFunctions";
import { Icons } from "../../assets/icons";
import { CommonApi } from "../../services/CommonAPI";
import { PAGES_DATA } from "../../utils/commonUtils";

type Props = {
  notifications?: any;
};

const Topbar = (props: Props) => {
  const {user:userData} = useContext<UserContextInterface>(userContext);
  const location = useLocation();
  const { pathname } = location;
  // const user = "admin";

  const navigate = useNavigate();

  const dItems: MenuProps["items"] = [
    {
      label: (
        <Button
          state="black-outlined"
          type="outlined"
          className="border-0 w-full"
          onClick={() => {
            navigate(ROUTES.SETTINGS);
          }}
        >
          <div className="flex gap-2 items-center">My account</div>
        </Button>
      ),
      key: "0",
    },
    {
      label: (
        <Button
          state="black-outlined"
          type="outlined"
          className="border-0 w-full"
          onClick={() => {
            window.open("mailto:support@congle.in");
          }}
        >
          <div className="flex gap-2 items-center">Help & support</div>
        </Button>
      ),
      key: "1",
    },
    {
      label: (
        <Button
          state="black-outlined"
          type="outlined"
          className="border-0 w-full"
          onClick={() => {
            handleLogout();
          }}
        >
          <div className="flex gap-2 items-center">Log out</div>
        </Button>
      ),
      key: "2",
    },
  ];

  const handleMarkAllNotificationsAsRead = async (readNotifications: string[]) => {
    try{
      await CommonApi.MarkNotificationsRead({notifications:readNotifications})
    }
    catch(err){
      console.log(err)
    }
  }


  return (
    <div
      className={`relative flex justify-between items-center py-3 w-full h-[80px] z-10`}
    >
      {/* {user === "cafe" ? <StatusToggler /> : <div></div>} */}
      <div></div>
      <div
        className={`flex gap-4 h-full items-center ${pathname === "/dashboard" ? "text-main_bg" : "text-text_high"
          }`}
      >
        <Dropdown
        onOpenChange={(open)=>{
          if(open && props.notifications?.length !== 0){
            handleMarkAllNotificationsAsRead(
              props.notifications?.map((item: any) => item._id)
            )
          }
        }}
        trigger={["click"]}
          dropdownRender={() => (
            <div className="bg-white rounded-md shadow-md p-2">
              <div className="p-2">
                <h6 className="text-sm">Notifications</h6>
              </div>
              <div className="p-2">
                {props.notifications?.length !== 0 ? props.notifications?.map((item: any, index: number) => (
                  <div 
                  className="border-b p-2 flex gap-2 items-center cursor-pointer" key={index}
                  onClick={()=>{
                    navigate(PAGES_DATA.PENDING.link)
                  }}
                  >
                    <div className="w-[40px] h-[40px] bg-primary rounded-full flex items-center justify-center">
                      <img src={Icons.LOGO_WHITE_NO_BG} className="w-[30px] h-[30px]" alt="" />
                    </div>
                    <div className="grow flex flex-col gap-2">
                      <h6 className="text-sm">New Booking Arrived</h6>
                      <p className="text-xs">{item.content}</p>
                    </div>
                  </div>
                ))
                  :
                  <div className="p-4">
                    <h6 className="text-sm">No New Notifications</h6>
                  </div>
                }
              </div>
            </div>
          )}
          placement="bottom"
        >
          <div className="relative p-2">
            <GoBellFill className="w-[20px] h-[20px]" />
            <div className="text-xs absolute -top-0.5 -right-0.5 w-[18px] h-[18px] bg-primary flex items-center justify-center text-[white] rounded-full">
              {props.notifications?.length || 0}
            </div>
          </div>
        </Dropdown>
        <div className="w-[1px] h-[40px] bg-border_light"></div>
        <Dropdown
          rootClassName="w-[200px] status-dropdown"
          menu={{ items: dItems, className: "top-4" }}
          trigger={["click"]}
        >
          <div className="flex gap-3 items-center cursor-pointer">
            <div className="rounded-full h-[32px] w-[32px] bg-gray-200">
              {userData?.profile_pic ? (
                <img
                  src={userData?.profile_pic}
                  className="w-full h-full rounded-full object-cover"
                  alt=""
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <h6 className="text-sm text-black_300">
                    {userData?.name?.[0]}
                  </h6>
                </div>
              )}
            </div>
            <div>
              <h6 className="text-sm">{userData?.name}</h6>
              <p className="text-xs">
                {userData?.role === "cafe_admin"
                  ? "Cafe"
                  : userData?.role === "admin"
                    ? "Admin"
                    : ""}
              </p>
            </div>
            <AiOutlineCaretDown />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default Topbar;
