import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../components/Partial/BreadCrumb";
import { BREADCRUMB_OPTION, FILTER_TABS } from "../../../utils/commonUtils";
import FilterTabs from "../../../components/Partial/FilterTabs";
import Table from "../../../components/Table/Table";
import { IMAGES } from "../../../assets/images";
import moment from "moment";
import { Icons } from "../../../assets/icons";
import { AdminApi } from "../../../services/AdminAPI";
import Button from "../../../components/Partial/Button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../router/routerConfig";
import Modal from "../../../components/Partial/Modal";
import WarningPop from "../../../components/smallPopups/WarningPop";

type Props = {};

const Venues = (props: Props) => {
  const [filters, setFilters] = useState<any>({
    date: "all",
  });
  const [selectedVenue, setSelectedVenue] = useState<string>();

  const navigate = useNavigate();

  const [data, setData] = useState<any>([]);
  const [counts, setCounts] = useState<any>({});

  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const handleOpenDeleteModal = () => setIsDeleteModal(true);
  const handleCloseDeleteModal = () => {
    setIsDeleteModal(false);
    setSelectedVenue("");
  };

  const handleFavourite = async (id: string) => {
    try {
      const res = await AdminApi.handleCafeFavouriteById(id);
      if (res) {
        getData();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await AdminApi.DeleteCafeById(selectedVenue);
      if (res.success) {
        getData();
      }
      handleCloseDeleteModal();
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    try {
      const params = {
        date: filters.date,
      };
      const res = await AdminApi.GetAllCafes(params);
      if (res) {
        setData(res.data);
        setCounts(res.count);
      } else {
        setData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "Venue Name",
      dataIndex: "name",
      render: (d: any, data: any) => (
        <div className="flex gap-3 items-center">
          <div className="flex ">
            <img
              src={data?.photoUrl || IMAGES.BannerAuth}
              className="rounded-full h-11 w-11 border"
              alt=""
            />
          </div>
          <div>
            <h4 className="text-sm">{data?.name}</h4>
          </div>
        </div>
      ),
    },
    {
      title: "Contact",
      dataIndex: "phone",
      key: "phone",
      render: (d: any) => (
        <div>
          <h4 className="text-sm">{d || "Not Mentioned"}</h4>
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (d: any) => (
        <div className="max-w-[240px]">
          <h4 className="truncate">{d || "Not Mentioned"}</h4>
        </div>
      ),
    },
    {
      title: "Completed meetings",
      dataIndex: "completedMeetings",
      key: "completedMeetings",
      render: (d: any) => (
        <div>
          <h4 className="">{d}</h4>
        </div>
      ),
    },
    {
      title: "Joined",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (d: any) => <div>{moment(moment(d)).fromNow()}</div>,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "action",
      render: (d: any, data: any) => (
        <div className="flex gap-3 items-center">
          <div
            className="h-8 w-8 rounded-full border flex items-center justify-center cursor-pointer hover:bg-gray-100"
            onClick={() => {
              handleFavourite(data._id);
            }}
          >
            <img
              src={
                data?.isFavourite
                  ? Icons.ADMIN_VENUE_FAVOURITE_SELECTED
                  : Icons.ADMIN_VENUE_FAVOURITE
              }
              alt=""
            />
          </div>
          <div
            className="h-8 w-8 rounded-full border flex items-center justify-center cursor-pointer hover:bg-gray-100"
            onClick={() => {
              navigate(`${ROUTES.ADMIN.ADD_VENUES}/${data._id}`);
            }}
          >
            <img src={Icons.ADMIN_VENUE_EDIT} alt="" />
          </div>
          <div
            className="h-8 w-8 rounded-full border flex items-center justify-center cursor-pointer hover:bg-gray-100"
            onClick={() => {
              handleOpenDeleteModal();
              setSelectedVenue(data._id);
            }}
          >
            <img src={Icons.ADMIN_VENUE_DELETE} alt="" />
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [filters]);

  return (
    <div>
      <Modal
        open={isDeleteModal}
        onCancel={handleCloseDeleteModal}
        onOk={handleCloseDeleteModal}
        width={"400px"}
      >
        <WarningPop
          title="Are you sure you want to remove?"
          description="Do you want to delete this venue? This action can’t be undone"
          submitText="Remove this venue"
          cancelText="Cancel"
          handleSubmit={handleDelete}
          onCancel={handleCloseDeleteModal}
        />
      </Modal>
      <div className="flex justify-between items-end">
        <div>
          <h4 className="text-2xl">Venues</h4>

          <BreadCrumb items={BREADCRUMB_OPTION.ADMIN.VENUES} />
        </div>
        <Button
          state="success"
          type="filled"
          onClick={() => {
            navigate(ROUTES.ADMIN.ADD_VENUES);
          }}
        >
          <div className="flex gap-1 items-center">
            <img alt="Add-icon" className="h-4 w-4" src={Icons.ADD_WHITE} />
            Add Venue
          </div>
        </Button>
      </div>

      <div className="mt-9 flex gap-6 items-center">
        <FilterTabs
          filters={FILTER_TABS.USERS}
          value={filters.date}
          onChange={(e) => {
            setFilters({ ...filters, date: e });
          }}
        />
      </div>

      {/* Stats */}
      <div className=" mt-6 ">
        <div className="grid sm:grid-cols-4 grid-cols-1 gap-6 relative z-10">
          <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
            <h4 className="text-lg text-black_300 flex items-center gap-1">
              <img
                src={Icons.ADMIN_DASHBOARD_TOTAL_USERS}
                className="w-[24px] h-[24px]"
                alt=""
              />
              Total Venues
            </h4>
            <h3 className="text-[32px] text-black-500 mt-2">
              {counts.cafeCount || 0}
            </h3>
            {/* <div className="flex items-center p-1 text-sm mt-4">
              <div className="flex items-center text-primiary p-1 ">
                <p>10%</p>
                <AiFillCaretUp className="text-primary" />
              </div>
              <p className="text-gray-400">+150 today</p>
            </div> */}
          </div>
          <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
            <h4 className="text-lg text-black_300 flex items-center gap-1">
              <img
                src={Icons.DASHBOARD_TOTAL_BOOKINGS}
                className="w-[18px] h-[18px]"
                alt=""
              />
              Revenue generated
            </h4>
            <h3 className="text-[32px] text-black-500 mt-2">
              ₹{counts?.bookings?.totalAmountCharged || 0}
            </h3>
            {/* <div className="flex items-center p-1 text-sm mt-4">
              <div className="flex items-center text-primiary p-1 ">
                <p>10%</p>
                <AiFillCaretUp className="text-primary" />
              </div>
              <p className="text-gray-400">+₹15 today</p>
            </div> */}
          </div>
          <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
            <h4 className="text-lg text-black_300 flex items-center gap-1">
              <img
                src={Icons.DASHBOARD_AVG_BILL_VAL}
                className="w-[18px] h-[18px]"
                alt=""
              />
              Completed Meetings
            </h4>
            <h3 className="text-[32px] text-black-500 mt-2">
              {counts?.bookings?.completed || 0}
            </h3>
            {/* <div className="flex items-center p-1 text-sm mt-4">
              <div className="flex items-center text-primiary p-1 ">
                <p>10%</p>
                <AiFillCaretUp className="text-primary" />
              </div>
              <p className="text-gray-400">+₹150 today</p>
            </div> */}
          </div>
          <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
            <h4 className="text-lg text-black_300 flex items-center gap-1">
              <img
                src={Icons.DASHBOARD_OFFER_REDEMPTION}
                className="w-[18px] h-[18px]"
                alt=""
              />
              Incompleted meetings
            </h4>
            <h3 className="text-[32px] text-black-500 mt-2">
              {counts?.bookings?.other || 0}
            </h3>
            {/* <div className="flex items-center p-1 text-sm mt-4">
              <div className="flex items-center text-primiary p-1 ">
                <p>10%</p>
                <AiFillCaretUp className="text-primary" />
              </div>
              <p className="text-gray-400">+₹150 today</p>
            </div> */}
          </div>
        </div>
      </div>

      <div className="mt-6">
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
};

export default Venues;
