import React, { useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import FilterTabs from "../../components/Partial/FilterTabs";
import Table from "../../components/Table/Table";
import { IMAGES } from "../../assets/images";
import Button from "../../components/Partial/Button";
import { Dropdown, MenuProps } from "antd";
import Modal from "../../components/Partial/Modal";
import WarningPop from "../../components/smallPopups/WarningPop";
import { BookingApi } from "../../services/BookingAPI";
import { BOOOKING_STATUSES } from "../../utils/attributeValuse";
import { Toast } from "../../wrapper/ToastWrapper";
import moment from "moment";
import BreadCrumb from "../../components/Partial/BreadCrumb";
import {
  BREADCRUMB_OPTION,
  FILTER_TABS,
  SNACKBAR_MESSAGES,
} from "../../utils/commonUtils";

type Props = {};

interface DataType {
  key: React.Key;
  name: string;
  age: number;
  address: string;
}

const Pending = (props: Props) => {
  const [selectedRows, setSelectedRows] = useState<any>([]);

  const [isRejectModal, setIsRejectModal] = useState(false);
  const handleOpenRejectModal = () => setIsRejectModal(true);
  const handleCloseRejectModal = () => setIsRejectModal(false);

  const [selectedBooking, setSelectedBooking] = useState<string>();

  // const [loading, setLoading] = useState(false);

  const [data, setData] = useState<any[]>([]);

  const [filters, setFilters] = useState<any>({
    date: "all",
  });

  const [isMany, setIsMany] = useState(false);

  const getData = async () => {
    // setLoading(true);
    const params = {
      date: filters.date,
    };
    try {
      const res = await BookingApi.GetBookings(
        BOOOKING_STATUSES.PENDING,
        params
      );
      const arr = [];
      for (const iterator of res.data) {
        const obj = {
          ...iterator,
          key: iterator._id,
        };
        arr.push(obj);
      }
      if (res && res.success) {
        setData(arr);
      }
    } catch (err) {
      Toast.Error("Error while fetching data");
    }
    // setLoading(false);
  };

  const handleSubmit = async (status: string) => {
    console.log("selectedBooking", selectedBooking);
    console.log(data?.find((d) => d._id === selectedBooking));

    // setLoading(true);
    try {
      const res = await BookingApi.UpdateBooking(selectedBooking, { status });
      if (res && res.success) {
        setSelectedBooking(undefined);
        if (status === BOOOKING_STATUSES.REJECTED) {
          handleCloseRejectModal();
          Toast.Error(SNACKBAR_MESSAGES.MARK_REJECT);
        } else {
          Toast.Success(SNACKBAR_MESSAGES.MARK_APPROVE);
        }
        getData();
      }
    } catch (err) {
      Toast.Error("Error while approving booking");
    }
  };

  const handleSubmitMany = async (status: string) => {
    // setLoading(true);
    try {
      const res = await BookingApi.UpdateManyBookings(status, {
        booking_ids: selectedRows.map((d: any) => d._id),
      });
      console.log("res", res);
      if (res && res.success) {
        setSelectedRows([]);
        if (status === BOOOKING_STATUSES.REJECTED) {
          handleCloseRejectModal();
          Toast.Error(SNACKBAR_MESSAGES.MARK_REJECT);
        } else {
          Toast.Success(SNACKBAR_MESSAGES.MARK_APPROVE);
        }
        getData();
      }
    } catch (err) {
      Toast.Error("Error while approving booking");
      // setLoading(false);
    } finally {
      // setIsMany(false);
    }
  };

  const dItems: MenuProps["items"] = [
    {
      label: (
        <Button state="warning" className="border-0 w-full">
          <div className="flex gap-2 items-center">Pending</div>
        </Button>
      ),
      key: "0",
    },
    {
      label: (
        <Button
          onClick={() => {
            handleSubmit(BOOOKING_STATUSES.ACCEPTED);
          }}
          state="success"
          className="border-0 w-full"
        >
          <div className="flex gap-2 items-center">Approved</div>
        </Button>
      ),
      key: "1",
    },
    {
      label: (
        <Button
          state="danger"
          className="border-0 w-full"
          onClick={handleOpenRejectModal}
        >
          <div className="flex gap-2 items-center">Reject</div>
        </Button>
      ),
      key: "2",
      rootClassName: "hover:bg-transparent p-0",
      popupClassName: "hover:bg-transparent p-0",
      className: "hover:bg-transparent p-0",
    },
  ];

  const columns = [
    {
      title: "Booking ID",
      dataIndex: "_id",
      key: "booking_id",
    },
    {
      title: "User Profile",
      dataIndex: "user",
      render: (d: any, data: any) => (
        <div className="flex gap-3 items-center">
          <div className="flex relative">
            <img
              src={data?.sender?.photos?.[0]?.photoUrl || IMAGES.BannerAuth}
              className="rounded-full h-11 w-11 border"
              alt=""
            />
            <img
              src={data?.receiver?.photos?.[0]?.photoUrl || IMAGES.BannerAuth}
              className="rounded-full h-11 w-11 border -ml-2"
              alt=""
            />
          </div>
          <div>
            <h4 className="text-sm">
              {data?.sender?.name} / {moment().diff(data?.sender?.dob, "years")}
            </h4>
            <h4 className="text-sm">
              {data?.receiver?.name} /{" "}
              {moment().diff(data?.receiver?.dob, "years")}
            </h4>
          </div>
        </div>
      ),
    },
    {
      title: "Email ID",
      dataIndex: "sender",
      key: "email",
      render: (d: any, data: any) => (
        <div>
          <h4 className="text-sm">{data?.sender?.email || "Not Mentioned"}</h4>
          <h4 className="text-sm">
            {data?.receiver?.email || "Not Mentioned"}
          </h4>
        </div>
      ),
    },
    {
      title: "Contact",
      dataIndex: "sender",
      key: "contact",
      render: (d: any) => (
        <div>
          <h4 className="text-sm">{d?.phoneno}</h4>
        </div>
      ),
    },
    {
      title: "Booking date & time",
      dataIndex: "date",
      key: "date",
      render: (_: any, d: any) => (
        <>
          {moment(d.date).format("Do MMM YYYY")} | {moment(d.date).format("LT")}
        </>
      ),
    },
    {
      title: "Time",
      dataIndex: "updatedAt",
      key: "time",
      render: (d: any) => <>{moment(moment(d)).fromNow()}</>,
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (d: any) => (
        <div>
          <Dropdown
            rootClassName="w-[200px] status-dropdown"
            menu={{ items: dItems }}
            trigger={["click"]}
          >
            <Button
              state="warning"
              className="border-0"
              onClick={() => {
                setSelectedBooking(d);
                console.log("d", d);
              }}
            >
              <div className="flex gap-2 items-center">
                Pending
                <AiFillCaretDown />
              </div>
            </Button>
          </Dropdown>
        </div>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSelectedRows(selectedRows);
      if (selectedRows.length === 0) setIsMany(false);
    },
    getCheckboxProps: (record: DataType) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };

  useEffect(() => {
    getData();
  }, [filters.date]);

  return (
    <div>
      <Modal
        open={isRejectModal}
        onCancel={handleCloseRejectModal}
        onOk={handleCloseRejectModal}
        width={"400px"}
      >
        <WarningPop
          onCancel={handleCloseRejectModal}
          handleSubmit={() => {
            if (isMany) {
              handleSubmitMany(BOOOKING_STATUSES.REJECTED);
            } else handleSubmit(BOOOKING_STATUSES.REJECTED);
          }}
          title={`Are you sure you want to reject?`}
          description={`This action cannot be undone. It will be permanently cancelled, and the user will be notified to reschedule their meet`}
          submitText={`Reject`}
          cancelText={`Cancel`}
        />
      </Modal>
      <h4 className="text-2xl">REQUESTS</h4>

      {/* Breadcrumb */}
      <BreadCrumb items={BREADCRUMB_OPTION.REQUESTS_PENDING} />

      <div className="mt-9 flex gap-6 items-center">
        <FilterTabs
          filters={FILTER_TABS.REQUESTS}
          value={filters.date}
          onChange={(e) => {
            setFilters({ ...filters, date: e });
          }}
        />

        {selectedRows.length > 0 && (
          <div className="flex gap-2 h-full">
            <Button
              onClick={() => handleSubmitMany(BOOOKING_STATUSES.ACCEPTED)}
              state="success"
              className="h-full"
            >
              Approve
            </Button>
            <Button
              onClick={() => {
                handleOpenRejectModal();
                setIsMany(true);
              }}
              state="danger"
              className="h-full"
            >
              Reject
            </Button>
          </div>
        )}
      </div>

      <div className="mt-6">
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          dataSource={data}
          columns={columns}
        />
      </div>
    </div>
  );
};

export default Pending;
