import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const CommonApi = {
  UploadToCloud: async (data, cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/storage",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
      data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  PostNotificationRequest: async (cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/booking/test-notification",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  GetNotifications: async (cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/cafe-notification",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  MarkNotificationsRead: async (data,cancel = false) => {
    const token = sessionStorage.getItem("token");
    const response = await api.request({
      url: "/cafe-notification/mark-all",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(CommonApi);
