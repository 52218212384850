import React from "react";
import { Icons } from "../../assets/icons";
import Button from "../Partial/Button";
import Input from "../FormFileds/Input";

type Props = {
  onCancel?: () => void;
  handleSubmit?: () => void;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CompleteBookingPop = (props: Props) => {
  return (
    <div className="pt-[52px] flex flex-col items-center justify-center">
      <div className="p-6 rounded-full bg-primary_light">
        <img src={Icons.GREEN_BOOKMARK} alt="" />
      </div>
      <div className="mt-3">
        <h4 className="text-center text-xl font-600">
          Great Hosting! Just One More Step...
        </h4>
        <p className="text-center font-400 mt-2 text-primary text-sm">
          Please enter the bill value to successfully mark the meeting as
          complete.
        </p>
      </div>
      <div className="w-full mt-3">
        <Input prefix="₹ |" value={props.value} onChange={props.onChange} />
      </div>
      <div className="flex justify-between w-full mt-8">
        <Button
          onClick={props.onCancel}
          state="black-outline"
          className="bg-transparent"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (props?.handleSubmit && props.value) props?.handleSubmit();
          }}
          state={props.value ? "success" : "black-outlined"}
          type={props?.value ? "filled" : "outlined"}
          className={
            props.value ? "" : "bg-[#ADADAD] text-white cursor-not-allowed"
          }
        >
          Mark complete
        </Button>
      </div>
    </div>
  );
};

export default CompleteBookingPop;
