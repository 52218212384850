import { Menu } from "antd";
import React, {  useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Topbar from "../Partial/Topbar";
import { Icons } from "../../assets/icons";
import { IMAGES } from "../../assets/images";
import { userContext } from "./../../utils/contexts";
import { User } from "../../interfaces";
import { PAGES_DATA } from "../../utils/commonUtils";
import { ROUTES } from "../../router/routerConfig";
import { BASE_URL } from "../../utils/constants";
import { CommonApi } from "../../services/CommonAPI";
import NotificationSound from "./../../assets/tunes/notification.mp3";
import { Toast } from "../../wrapper/ToastWrapper";

type Props = {
  children?: React.ReactNode;
};
const DashboardLayout = (props: Props) => {
  const [selectedKey, setSelectedKey] = useState("1");
  const [collapsed, setCollapsed] = useState(false);
  // const [authState, setAuthState] = useState(true);
  const [notifications, setNotifications] = useState<any>([]);

  const onClickMenuItem = (e: any) => {
    setSelectedKey(e.key);
  };

  const location = useLocation();

  const [keyCollpaseMenu, setKeyCollpaseMenu] = useState<any>({
    "2": false,
  });

  
  const [user, setUser] = useState<User | null>(()=>{
    const storedUser = sessionStorage.getItem("user");
  const initialUser = storedUser ? JSON.parse(storedUser) : null;
  return initialUser;
  });

  const items = [
    {
      key: "1",
      icon: (
        <img
          src={Icons.SIDEBAR_DASHBOARD}
          className="w-[18px] h-[18px]"
          alt=""
        />
      ),
      label: PAGES_DATA.DASHBOARD.title,
      path: PAGES_DATA.DASHBOARD.link,
    },
    {
      key: "2",
      icon: (
        <img
          src={Icons.SIDEBAR_REQUESTS}
          className="w-[18px] h-[18px]"
          alt=""
        />
      ),
      label: "Requests",
      path: "/pending",
      type: "subMenu",
      childrens: [
        {
          key: 1,
          icon: (
            <img
              src={Icons.SIDEBAR_REQUESTS}
              className="w-[18px] h-[18px]"
              alt=""
            />
          ),
          label: PAGES_DATA.PENDING.title,
          path: "/pending",
        },
        {
          key: 2,
          icon: (
            <img
              src={Icons.SIDEBAR_REQUESTS}
              className="w-[18px] h-[18px]"
              alt=""
            />
          ),
          label: PAGES_DATA.UPCOMING.title,
          path: PAGES_DATA.UPCOMING.link,
        },
        {
          key: 3,
          icon: (
            <img
              src={Icons.SIDEBAR_REQUESTS}
              className="w-[18px] h-[18px]"
              alt=""
            />
          ),
          label: PAGES_DATA.COMPLETED.title,
          path: PAGES_DATA.COMPLETED.link,
        },
        {
          key: 5,
          icon: (
            <img
              src={Icons.SIDEBAR_REQUESTS}
              className="w-[18px] h-[18px]"
              alt=""
            />
          ),
          label: PAGES_DATA.DIDNT_SHOW.title,
          path: PAGES_DATA.DIDNT_SHOW.link,
        },
        {
          key: 4,
          icon: (
            <img
              src={Icons.SIDEBAR_REQUESTS}
              className="w-[18px] h-[18px]"
              alt=""
            />
          ),
          label: PAGES_DATA.CANCELLED.title,
          path: PAGES_DATA.CANCELLED.link,
        },
      ],
    },
    // {
    //   key: "7",
    //   icon: (
    //     <img src={Icons.SIDEBAR_OFFERS} className="w-[18px] h-[18px]" alt="" />
    //   ),
    //   label: "Offers",
    //   path: "/offers",
    // },
    {
      key: "8",
      icon: (
        <img src={Icons.SIDEBAR_PROFILE} className="w-[18px] h-[18px]" alt="" />
      ),
      label: PAGES_DATA.PROFILE.title,
      path: PAGES_DATA.PROFILE.link,
    },
    {
      key: "9",
      icon: (
        <img
          src={Icons.SIDEBAR_SETTINGS}
          className="w-[18px] h-[18px]"
          alt=""
        />
      ),
      label: PAGES_DATA.SETTINGS.title,
      path: PAGES_DATA.SETTINGS.link,
    },
  ];

  const navigate = useNavigate();

  const getNotifications = async () => {
    try {
      const response = await CommonApi.GetNotifications();
      if (response) {
        setNotifications(response.data);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const RequestForNotification = async () => {
    const cafe_id = sessionStorage.getItem("cafe_id");
    if (!cafe_id) return;
    const eventSource = new EventSource(
      BASE_URL + "/booking/notification?user=" + cafe_id,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      } as any
    );

    eventSource.onmessage = (event) => {
      console.log(event.data);
      const newBooking = event.data;
      const audio = new Audio(NotificationSound);
      audio.play();
      Toast.Success(newBooking);
      getNotifications();
    };

    eventSource.onerror = (error) => {
      console.error("EventSource failed:", error);
    };
  };

  const notificationRef = React.useRef<any>(null);

  const testNotification = async () => {
    notificationRef.current = await setInterval(async () => {
      await CommonApi.PostNotificationRequest();
    }, 10000);
  };

  useEffect(() => {
    if (sessionStorage.getItem("role") !== "cafe_admin") {
      navigate(ROUTES.LOGIN);
    }
  }, []);

  useEffect(() => {
    RequestForNotification();
    getNotifications()
  }, []);

  return (
    <userContext.Provider value={{user,setUser }}>
      <div>
        <div className="flex">
          <Menu
            onClick={onClickMenuItem}
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            style={{
              width: !collapsed ? "260px" : "64px",
              minWidth: !collapsed ? "260px" : "64px",
              backgroundColor: "#FFFFFF",
              minHeight: "100vh",
              maxHeight: "100vh",
              border: 0,
              display: "flex",
              flexDirection: "column",
              gap: "0px",
            }}
            // items={items2}
            selectedKeys={[selectedKey]}
            inlineCollapsed={collapsed}
          >
            <div
              className={`flex mt-2 justify-start ${
                collapsed ? "pl-[14px]" : "pl-[20px]"
              }`}
            >
              <div
                className="rounded-[12px] p-2 h-[35px] w-[35px] border border-border_light flex items-center justify-center cursor-pointer"
                onClick={() => {
                  setCollapsed(!collapsed);
                }}
              >
                {collapsed ? (
                  <img src={Icons.SIDEBAR_CROSS} alt="" />
                ) : (
                  <img src={Icons.SIDEBAR_HAMBURGER} alt="" />
                )}
              </div>
            </div>
            <div
              className={`flex h-[60px] justify-start mt-8 items-center gap-3 mb-5 ${
                collapsed ? "pl-[14px]" : "pl-[20px]"
              }`}
            >
              <img src={IMAGES.ROUND_LOGO} className="h-[34px] w-[34px]" />
              {collapsed ? null : (
                <div className=" w-full text-xl truncate">CONGLE CAFE</div>
              )}
            </div>
            {items.map((item) => (
              <>
                <div
                  onClick={() => {
                    if (item.childrens) {
                      setKeyCollpaseMenu({
                        ...keyCollpaseMenu,
                        [item.key]: !keyCollpaseMenu[item.key],
                      });
                    } else {
                      navigate(item.path);
                    }
                  }}
                  className={`my-1 flex py-3 cursor-pointer px-6 hover:bg-bg_light hover:border-l-4 hover:border-primary hover:pl-[16px] justify-between items-center ${
                    location.pathname == item.path ||
                    (item.childrens &&
                      item.childrens.some(
                        (childItem) => location.pathname == childItem.path
                      ))
                      ? "bg-bg_light border-l-4 border-primary pl-[16px]"
                      : "text-black pl-[20px]"
                  } ${collapsed ? "" : ""}}`}
                >
                  <div className="flex gap-2">
                    {item.icon}
                    {!collapsed && (
                      <div className="text-sm truncate">{item.label}</div>
                    )}
                  </div>
                  {item.childrens && !collapsed && (
                    <div>
                      <img
                        style={{
                          transform: keyCollpaseMenu[item.key]
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                          transition: "all 0.3s ease-in-out",
                        }}
                        src={Icons.CARET_DOWN}
                        alt=""
                      />
                    </div>
                  )}
                </div>
                {item.childrens &&
                  keyCollpaseMenu[item.key] &&
                  !collapsed &&
                  item.childrens.map((childItem) => (
                    <div
                      onClick={() => {
                        navigate(childItem.path);
                      }}
                      className={`my-1 flex py-3 cursor-pointer px-6 hover:bg-bg_light hover:border-l-4 hover:border-primary hover:pl-[16px] justify-between items-center ${
                        location.pathname == childItem.path
                          ? "bg-bg_light border-l-4 border-primary pl-[16px]"
                          : "text-black pl-[20px]"
                      } ${collapsed ? "" : ""}}`}
                    >
                      <div className="flex gap-2">
                        <div className="text-sm truncate ml-[26px]">
                          {childItem.label}
                        </div>
                      </div>
                    </div>
                  ))}
                <div></div>
              </>
            ))}
          </Menu>
          <div
            className={`grow bg-main_bg relative ${
              location.pathname == "/profile" ? "pt-6" : "p-6"
            }`}
          >
            <div className={`${location.pathname == "/profile" ? "px-6" : ""}`}>
              <Topbar notifications={notifications} />
            </div>

            {props.children}
          </div>
        </div>
      </div>
    </userContext.Provider>
  );
};

export default DashboardLayout;
