import React, { useEffect } from "react";
import Button from "../../components/Partial/Button";
import { Icons } from "../../assets/icons";
import Select from "../../components/FormFileds/Select";
import Input from "../../components/FormFileds/Input";
import InputArea from "../../components/FormFileds/InputArea";
import { useNavigate, useParams } from "react-router-dom";
import { DISCOUNT_TYPES, DISCOUNT_VALIDITY } from "../../utils/options";
import { OffersApi } from "../../services/OffersAPI";
import BreadCrumb from "../../components/Partial/BreadCrumb";
import { BREADCRUMB_OPTION, SNACKBAR_MESSAGES } from "../../utils/commonUtils";
import { Toast } from "../../wrapper/ToastWrapper";
import axios from "axios";

type Props = {};

const AddOffers = (props: Props) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [inputData, setInputData] = React.useState<any>({
    discount_type: "",
    title: "",
    discount_value: "",
    min_bill: "",
    max_discount: "",
    coupon_code: "",
    validity: "",
    description: "",
  });

  console.log(id);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInputData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeSelect = (e: any, name: string) => {
    setInputData((prev: any) => ({
      ...prev,
      [name]: e,
    }));
  };

  const clearData = () => {
    setInputData({
      discount_type: "",
      title: "",
      discount_value: "",
      min_bill: "",
      max_discount: "",
      coupon_code: "",
      validity: "",
      description: "",
    });
  };

  const handleSubmit = async () => {
    try {
      const res = await OffersApi.PostAddOffer(inputData);
      if (res) {
        Toast.Success(SNACKBAR_MESSAGES.ADD_OFFER);
        clearData();
        navigate(-1);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  const handleEdit = async () => {
    try {
      const res = await OffersApi.UpdateOffer(id, inputData);
      if (res) {
        Toast.Success(SNACKBAR_MESSAGES.ADD_OFFER);
        getOffer();
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  const getOffer = async () => {
    if (!id) return;
    try {
      const res = await OffersApi.GetOffer(id);
      if (res) {
        setInputData(res.data);
        console.log(res.data);
      }
    } catch (error) {
      if (error instanceof Error) {
        if (axios.isAxiosError(error)) {
          console.error(error);
          Toast.Error(error.response?.data.message ?? "Something went wrong");
          return;
        }
      }
      Toast.Error("Something went wrong");
    }
  };

  useEffect(() => {
    if (id) getOffer();
  }, [id]);
  return (
    <div>
      {/* Breadcrumb */}
      <div className="flex justify-between items-end">
        <div>
          <h4 className="text-2xl">Offers & discounts</h4>

          <BreadCrumb
            items={id ? BREADCRUMB_OPTION.OFFER : BREADCRUMB_OPTION.ADD_OFFERS}
          />
        </div>
        <div className="flex gap-4">
          <Button
            onClick={() => {
              navigate(-1);
            }}
            className="w-[120px]"
            state="black-outlined"
            type="filled"
          >
            <div className="flex items-center gap-1">
              <img src={Icons.CROSS_BLACK} className="w-4 h-4" alt="" />
              Cancel
            </div>
          </Button>
          <Button
            onClick={() => {
              if (id) handleEdit();
              else handleSubmit();
            }}
            className="w-[120px]"
            state="success"
            type="filled"
          >
            Save
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-4">
        <div className="lg:col-span-3 col-span-4">
          <div className="bg-white p-6 mt-6 rounded-lg border">
            <h4 className="text-lg">Add new offer</h4>
            <div className="grid grid-cols-2 mt-3.5 gap-x-3.5 gap-y-4">
              <div className="col-span-2">
                <label htmlFor="" className="text-black_300">
                  Title
                </label>
                <Input
                  className="mt-1 text-black_300"
                  placeholder="Enter the maximum discount value here"
                  value={inputData?.title}
                  onChange={handleChange}
                  name="title"
                  type="text"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="" className="text-black_300">
                  Discount type
                </label>
                <Select
                  className="mt-1 text-black_300"
                  placeholder="Select.."
                  options={DISCOUNT_TYPES}
                  value={inputData?.discount_type || null}
                  onChange={(e) => {
                    handleChangeSelect(e, "discount_type");
                  }}
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="" className="text-black_300">
                  Discount
                </label>
                <Input
                  className="mt-1 text-black_300"
                  placeholder="Enter the discount here"
                  value={inputData?.discount_value}
                  onChange={handleChange}
                  name="discount_value"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="" className="text-black_300">
                  Minimum bill value
                </label>
                <Input
                  className="mt-1 text-black_300"
                  placeholder="Enter the minimum bill value here"
                  value={inputData?.min_bill}
                  onChange={handleChange}
                  name="min_bill"
                  type="number"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="" className="text-black_300">
                  Maximum discount value
                </label>
                <Input
                  className="mt-1 text-black_300"
                  placeholder="Enter the maximum discount value here"
                  value={inputData?.max_discount}
                  onChange={handleChange}
                  name="max_discount"
                  type="number"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="" className="text-black_300">
                  Coupon code
                </label>
                <Input
                  className="mt-1 text-black_300"
                  placeholder="Enter the coupon code"
                  value={inputData?.coupon_code}
                  onChange={handleChange}
                  name="coupon_code"
                />
              </div>
              <div className="col-span-1">
                <label htmlFor="" className="text-black_300">
                  Offer validity
                </label>
                <Select
                  className="mt-1 text-black_300"
                  placeholder="Select.."
                  options={DISCOUNT_VALIDITY}
                  value={inputData?.validity}
                  onChange={(e) => {
                    handleChangeSelect(e, "validity");
                  }}
                />
              </div>
              <div className="col-span-2">
                <label htmlFor="" className="text-black_300">
                  Description
                </label>
                <InputArea
                  rows={5}
                  className="mt-1 text-black_300"
                  placeholder="Type category description here. . ."
                  value={inputData?.description}
                  onChange={handleChange}
                  name="description"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOffers;
