import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/Auth/Login";
import VerifyOtp from "../pages/Auth/VerifyOtp";
import DashboardLayout from "../components/Layout/DashboardLayout";
import Dashboard from "../pages/Dashboard/Dashboard";
import Pending from "../pages/Requests/Pending";
import DidntShow from "../pages/Requests/DidntShow";
import Completed from "../pages/Requests/Completed";
import Cancelled from "../pages/Requests/Cancelled";
import Upcoming from "../pages/Requests/Upcoming";
import Profile from "../pages/Profile/Profile";
import Offers from "../pages/Offers/Offers";
import Settings from "../pages/Settings/Settings";
import AddOffers from "../pages/Offers/AddOffer";

// Admin Pages
import AdminLayout from "../components/Layout/AdminLayout";
import AdminLogin from "../pages/admin/auth/Login";
import AdminDashboard from "../pages/admin/dashboard/Dashboard";
import Users from "../pages/admin/users/Users";
import Venues from "../pages/admin/cafe/Venues";
import AdminSettings from "../pages/admin/settings/Settings";
import { ROUTES } from "./routerConfig";
import AddVenue from "../pages/admin/cafe/AddVenue";

type Props = {};

const Router = (props: Props) => {
  return (
    <Routes>
      <Route path={ROUTES.LOGIN} element={<Login />} />
      <Route path={ROUTES.ADMIN.LOGIN} element={<AdminLogin />} />
      <Route path={ROUTES.VERIFY} element={<VerifyOtp />} />
      <Route
        path={ROUTES.DASHBOARD}
        element={<DashboardLayout children={<Dashboard />} />}
      />
      <Route
        path={ROUTES.PENDING}
        element={<DashboardLayout children={<Pending />} />}
      />
      <Route
        path={ROUTES.DIDNT_SHOW}
        element={<DashboardLayout children={<DidntShow />} />}
      />
      <Route
        path={ROUTES.COMPLETED}
        element={<DashboardLayout children={<Completed />} />}
      />
      <Route
        path={ROUTES.CANCELLED}
        element={<DashboardLayout children={<Cancelled />} />}
      />
      <Route
        path={ROUTES.UPCOMING}
        element={<DashboardLayout children={<Upcoming />} />}
      />
      <Route
        path={ROUTES.PROFILE}
        element={<DashboardLayout children={<Profile />} />}
      />
      <Route
        path={ROUTES.OFFERS}
        element={<DashboardLayout children={<Offers />} />}
      />
      <Route
        path={ROUTES.ADD_OFFERS}
        element={<DashboardLayout children={<AddOffers />} />}
      />
      <Route
        path={`${ROUTES.OFFER}/:id`}
        element={<DashboardLayout children={<AddOffers />} />}
      />
      <Route
        path={ROUTES.SETTINGS}
        element={<DashboardLayout children={<Settings />} />}
      />
      <Route
        path={ROUTES.ADMIN.DASHBOARD}
        element={<AdminLayout children={<AdminDashboard />} />}
      />
      <Route
        path={ROUTES.ADMIN.USERS}
        element={<AdminLayout children={<Users />} />}
      />
      <Route
        path={ROUTES.ADMIN.VENUES}
        element={<AdminLayout children={<Venues />} />}
      />
      <Route
        path={`${ROUTES.ADMIN.ADD_VENUES}/:id`}
        element={<AdminLayout children={<AddVenue />} />}
      />
      <Route
        path={ROUTES.ADMIN.ADD_VENUES}
        element={<AdminLayout children={<AddVenue />} />}
      />
      <Route
        path={ROUTES.ADMIN.SETTINGS}
        element={<AdminLayout children={<AdminSettings />} />}
      />
    </Routes>
  );
};

export default Router;
