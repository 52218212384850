import React, { useEffect, useState } from "react";
import FilterTabs from "../../../components/Partial/FilterTabs";
import { IMAGES } from "../../../assets/images";
import { Icons } from "../../../assets/icons";
import { AdminApi } from "../../../services/AdminAPI";
import { FILTER_TABS } from "../../../utils/commonUtils";
import Overview from "../../../components/charts/AdminDashboardChart";
import {
  months,
} from "../../../utils/options";

type Props = {};

const Dashboard = (props: Props) => {
  const [filters, setFilters] = useState<any>({
    date: "all",
  });

  const [overviewData, setOverviewData] = useState<any>([]);
  const [data, setData] = useState<any>({});
  const getData = async () => {
    const params = {
      date: filters.date,
    };
    try {
      const res = await AdminApi.GetDashbaordStat(params);
      if (res) {
        setData(res.data);
        const arr = [];
        let bookingArr = [];
        let userArr = [];
        let invitationArr = [];
        if (res.data.overview.bookingOverviewData)
          bookingArr = res.data.overview.bookingOverviewData.map(
            (item: any) => {
              return {
                category: "booking",
                month: months[item.month],
                value: item.count,
              };
            }
          );
        if (res.data.overview.userOverviewData)
          userArr = res.data.overview.userOverviewData.map((item: any) => {
            return {
              category: "users",
              month: months[item.month],
              value: item.count,
            };
          });
        if (res.data.overview.invitationOverviewData)
          invitationArr = res.data.overview.invitationOverviewData.map(
            (item: any) => {
              return {
                category: "connection",
                month: months[item.month],
                value: item.count,
              };
            }
          );
        arr.push(...bookingArr, ...userArr, ...invitationArr);
        setOverviewData(arr);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData();
  }, [filters]);
  return (
    <div className="">
      <img
        src={IMAGES.DASHBOARD_BG}
        className="absolute h-[280px] w-full top-0 left-0 "
        style={{ zIndex: "5" }}
        alt="Logo"
      />
      {/* Header */}
      <div className="relative sm:flex justify-between pt-5 z-50 text-main_bg">
        <div>
          <h5 className="text-2xl z-auto uppercase">our Dashboard </h5>
          <p className="text-lg">Track All Key Stats in One Place</p>
        </div>
        {/* <img src={Icons.THREE_DOTS} className="w-[20px] h-[40px]" alt="" /> */}
        <FilterTabs
          filters={FILTER_TABS.USERS}
          value={filters.date}
          onChange={(e) => {
            setFilters({ ...filters, date: e });
          }}
        />
      </div>

      {/* Stats */}
      <div className="grid sm:grid-cols-4 grid-cols-1 gap-6 relative z-10 mt-12">
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img
              src={Icons.ADMIN_DASHBOARD_TOTAL_USERS}
              className="w-[24px] h-[24px]"
              alt=""
            />
            Total Users
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">
            {data?.usersStat?.count || 0}
          </h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+150 today</p>
          </div> */}
        </div>
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img
              src={Icons.ADMIN_DASHBOARD_ACTIVE_USERS}
              className="w-[18px] h-[18px]"
              alt=""
            />
            Active users
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">
            {data?.usersStat?.onlineUsers || 0}
          </h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+₹150 today</p>
          </div> */}
        </div>
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img src={Icons.HEART_GREEN} className="w-[18px] h-[18px]" alt="" />
            Total Connections
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">
            {data?.usersStat?.count || 0}
          </h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+₹15 today</p>
          </div> */}
        </div>
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img
              src={Icons.MALE_VS_FEMALE}
              className="w-[18px] h-[18px]"
              alt=""
            />
            Male/Female
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">
            {data?.usersStat?.maleUsers || 0}(M) /{" "}
            {data?.usersStat?.femaleUsers || 0}(F)
          </h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+₹150 today</p>
          </div> */}
        </div>
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img
              src={Icons.ADMIN_DASHBOARD_CAFE_COUNT}
              className="w-[18px] h-[18px]"
              alt=""
            />
            Total Venues
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">
            {data?.cafeCount?.count || 0}
          </h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+₹150 today</p>
          </div> */}
        </div>
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img
              src={Icons.DASHBOARD_TOTAL_EARNINGS}
              className="w-[18px] h-[18px]"
              alt=""
            />
            Revenue generated
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">
            ₹ {data?.bookingsStat?.totalAmountCharged || 0}
          </h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+₹150 today</p>
          </div> */}
        </div>
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img
              src={Icons.ADMIN_DASHBOARD_COMPLETED_MEETINGS}
              className="w-[18px] h-[18px]"
              alt=""
            />
            Completed meetings
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">
            {data?.bookingsStat?.completed || 0}
          </h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+₹15 today</p>
          </div> */}
        </div>
        <div className="col-span-1 bg-[white] p-5 rounded-[8px] bg-[white] border">
          <h4 className="text-lg text-black_300 flex items-center gap-1">
            <img
              src={Icons.ADMIN_DASHBOARD_INCOMPLETED_MEETINGS}
              className="w-[18px] h-[18px]"
              alt=""
            />
            Incomplete meetings
          </h4>
          <h3 className="text-[32px] text-black-500 mt-2">
            {data?.bookingsStat?.other || 0}
          </h3>
          {/* <div className="flex items-center p-1 text-sm mt-4">
            <div className="flex items-center text-primiary p-1 ">
              <p>10%</p>
              <AiFillCaretUp className="text-primary" />
            </div>
            <p className="text-gray-400">+₹150 today</p>
          </div> */}
        </div>
      </div>

      {/* Graphs */}
      <div className="grid grid-cols-3 mt-9 gap-6">
        <div className="col-span-3 rounded-[8px] bg-[white] p-6 border">
          <h4>OverView</h4>
          <Overview data={overviewData} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
