import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
  state?: string;
  type?: "outlined" | "filled";
  onClick?: () => void;
};

const Button = (props: Props) => {
  const dangerClassNames =
    props.type === "filled"
      ? "bg-error text-[white]"
      : "bg-error_light text-error border border-1 border-error";
  const successClassNames =
    props.type === "filled"
      ? "bg-primary text-[white]"
      : "bg-primary_light text-primary border border-1 border-primary";
  const warningClassNames =
    props.type === "filled"
      ? "bg-secondary text-[white]"
      : "bg-secondary_light text-secondary border border-1 border-secondary";
  const grayClassNames =
    props.type === "filled"
      ? "bg-gray-600 text-[white]"
      : "bg-gray-200 text-gray-600 border border-1 border-gray-600";
  const blackOutlinedClassNames =
    props.type === "filled"
      ? "bg-transparent text-[black] border border-1 border-black"
      : "bg-gray-200 text-[black] ";

  return (
    <button
      onClick={props.onClick}
      className={` px-3 rounded-[8px] text-sm font-600 leading-[20px] tracking-[0.07px] py-2 flex items-center justify-center ${
        props.state === "danger"
          ? dangerClassNames
          : props.state === "warning"
          ? warningClassNames
          : props.state === "success"
          ? successClassNames
          : props.state === "black-outlined"
          ? blackOutlinedClassNames
          : grayClassNames
      } ${props.className}`}
    >
      {props.children}
    </button>
  );
};

export default Button;
