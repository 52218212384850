import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Icons } from "../../assets/icons";
import Button from "../Partial/Button";

const Upload = ({ onChange }: { onChange?: (e: any) => any }) => {
  const onDrop = useCallback(
    (acceptedFiles: any) => {
      onChange && onChange(acceptedFiles);
    },
    [onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <div {...getRootProps()}>
      <input
        {...getInputProps()}
        type="file"
        onChange={(e) => {
          console.log("hi");

          console.log(e.target);
        }}
      />
      <div className="flex flex-col items-center justify-center gap-4 p-6 border border-dashed border-2 rounded-[8px]">
        <div className="p-2 rounded-full bg-primary_light h-9 w-9 flex items-center justify-center">
          <img src={Icons.UPLOAD} className="w-full" alt="" />
        </div>
        <p className="text-center text-black_300">
          Drag and drop image here, or click add image
        </p>
        <Button state="black-outlined">Add Image</Button>
      </div>
      {/* <ul>
        {acceptedFiles.map((file) => (
          <li key={file.name}>{file.name}</li>
        ))}
      </ul> */}
    </div>
  );
};
export default Upload;
