import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Topbar from "../Partial/Topbar";
import { Icons } from "../../assets/icons";
import { IMAGES } from "../../assets/images";
import type { MenuProps } from "antd";
import { ROUTES } from "../../router/routerConfig";
import { PAGES_DATA } from "../../utils/commonUtils";

type Props = {
  children?: React.ReactNode;
};
const AdminLayout = (props: Props) => {
  const [selectedKey, setSelectedKey] = useState("1");
  const [collapsed, setCollapsed] = useState(false);

  // const [authState, setAuthState] = useState(true);

  const onClickMenuItem = (e: any) => {
    setSelectedKey(e.key);
    // navigate(keyRouteMap[e.key])
  };

  const location = useLocation();
  console.log(location.pathname);

  type MenuItem = Required<MenuProps>["items"][number];

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    path?: string,
    className?: string,
    children?: MenuItem[],
    type?: "group"
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      path,
      className,
      type,
    } as MenuItem;
  }

  const [keyCollpaseMenu, setKeyCollpaseMenu] = useState<any>({
    "2": false,
  });

  const items = [
    {
      key: "1",
      icon: (
        <img
          src={Icons.SIDEBAR_DASHBOARD}
          className="w-[18px] h-[18px]"
          alt=""
        />
      ),
      label: PAGES_DATA.ADMIN.DASHBOARD.title,
      path: PAGES_DATA.ADMIN.DASHBOARD.link,
    },
    {
      key: "2",
      icon: (
        <img src={Icons.SIDEBAR_OFFERS} className="w-[18px] h-[18px]" alt="" />
      ),
      label: PAGES_DATA.ADMIN.USERS.title,
      path: PAGES_DATA.ADMIN.USERS.link,
    },
    {
      key: "3",
      icon: (
        <img src={Icons.SIDEBAR_PROFILE} className="w-[18px] h-[18px]" alt="" />
      ),
      label: PAGES_DATA.ADMIN.VENUES.title,
      path: PAGES_DATA.ADMIN.VENUES.link,
    },
    {
      key: "4",
      icon: (
        <img
          src={Icons.SIDEBAR_SETTINGS}
          className="w-[18px] h-[18px]"
          alt=""
        />
      ),
      label: PAGES_DATA.ADMIN.SETTINGS.title,
      path: PAGES_DATA.ADMIN.SETTINGS.link,
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("role") !== "admin") {
      navigate(ROUTES.LOGIN);
    }
  }, []);

  return (
    <div>
      <div className="flex">
        <Menu
          onClick={onClickMenuItem}
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
          style={{
            width: !collapsed ? "260px" : "64px",
            minWidth: !collapsed ? "260px" : "64px",
            backgroundColor: "#FFFFFF",
            minHeight: "100vh",
            maxHeight: "100vh",
            border: 0,
            display: "flex",
            flexDirection: "column",
            gap: "0px",
          }}
          // items={items2}
          selectedKeys={[selectedKey]}
          inlineCollapsed={collapsed}
        >
          <div
            className={`flex mt-2 justify-start ${collapsed ? "pl-[14px]" : "pl-[20px]"
              }`}
          >
            <div
              className="rounded-[12px] p-2 h-[35px] w-[35px] border border-border_light flex items-center justify-center cursor-pointer"
              onClick={() => {
                setCollapsed(!collapsed);
              }}
            >
              {collapsed ? (
                <img src={Icons.SIDEBAR_CROSS} alt="" />
              ) : (
                <img src={Icons.SIDEBAR_HAMBURGER} alt="" />
              )}
            </div>
          </div>
          <div
            className={`flex h-[60px] justify-start mt-8 items-center gap-3 mb-5 ${collapsed ? "pl-[14px]" : "pl-[20px]"
              }`}
          >
            <img src={IMAGES.ROUND_LOGO} className="h-[34px] w-[34px]" />
            {collapsed ? null : (
              <div className=" w-full text-xl truncate">CONGLE CAFE</div>
            )}
          </div>

          {/* {items.map((item) => (
            <Menu.Item
              onClick={() => {
                navigate(item.path);
              }}
              style={{
                color: "black",
                margin: "4px 0",
                paddingLeft: location.pathname == item.path ? "14px" : "20px",
                width: "100%",
                borderRadius: "0px",
                // padding: location.pathname == item.path ?"6px 0":"10px 0",
                borderLeft:
                  location.pathname == item.path ? "4px solid #03A790" : "none",
                backgroundColor:
                  location.pathname == item.path
                    ? COLORS.bg_light
                    : "transparent",
              }}
              className="sidebar-item"
              key={item.key}
              icon={item.icon}
            >
              {
                item.label
              }
            </Menu.Item>
          ))} */}
          {items.map((item: any) => (
            <>
              <div
                onClick={() => {
                  if (item.childrens) {
                    setKeyCollpaseMenu({
                      ...keyCollpaseMenu,
                      [item.key]: !keyCollpaseMenu[item.key],
                    });
                  } else {
                    navigate(item.path);
                  }
                }}
                className={`my-1 flex py-3 cursor-pointer px-6 hover:bg-bg_light hover:border-l-4 hover:border-primary hover:pl-[16px] justify-between items-center ${location.pathname == item.path ||
                    (item.childrens &&
                      item.childrens.some(
                        (childItem: any) => location.pathname === childItem.path
                      ))
                    ? "bg-bg_light border-l-4 border-primary pl-[16px]"
                    : "text-black pl-[20px]"
                  } ${collapsed ? "" : ""}}`}
              >
                <div className="flex gap-2">
                  {item.icon}
                  {!collapsed && (
                    <div className="text-sm truncate">{item.label}</div>
                  )}
                </div>
                {item.childrens && !collapsed && (
                  <div>
                    <img
                      style={{
                        transform: keyCollpaseMenu[item.key]
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                        transition: "all 0.3s ease-in-out",
                      }}
                      src={Icons.CARET_DOWN}
                      alt=""
                    />
                  </div>
                )}
              </div>
              {item.childrens &&
                keyCollpaseMenu[item.key] &&
                !collapsed &&
                item.childrens.map((childItem: any) => (
                  <div
                    onClick={() => {
                      navigate(childItem.path);
                    }}
                    className={`my-1 flex py-3 cursor-pointer px-6 hover:bg-bg_light hover:border-l-4 hover:border-primary hover:pl-[16px] justify-between items-center ${location.pathname == childItem.path
                        ? "bg-bg_light border-l-4 border-primary pl-[16px]"
                        : "text-black pl-[20px]"
                      } ${collapsed ? "" : ""}}`}
                  >
                    <div className="flex gap-2">
                      <div className="text-sm truncate ml-[26px]">
                        {childItem.label}
                      </div>
                    </div>
                  </div>
                ))}
              <div></div>
            </>
          ))}
        </Menu>
        <div
          className={`grow bg-main_bg relative ${location.pathname == "/profile" ? "pt-6" : "p-6"
            }`}
        >
          <div className={`${location.pathname == "/profile" ? "px-6" : ""}`}>
            <Topbar />
          </div>

          {props.children}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
